import React, {useEffect, useState} from 'react';
import AddTicketModal from "../Modal/AddTicket";
import {Role} from "../../Utils/Role";

const AddTicketIcon = ({ onClick }) => {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const [role, setRole] = useState(null)

    useEffect(() => {
        setRole(Role());
    }, [])

    return (
        <>
            <li className={'d-flex align-items-center subLi my-2'} onClick={handleShow}>
                <a className={'text-decoration-none'}>
                    <span className={'text-white text-decoration-none'}>Crea Ticket</span>
                </a>
            </li>

            <AddTicketModal show={showModal} handleClose={handleClose}/>
        </>
    );
};

export default AddTicketIcon;
