import {Col, Form, InputGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getMonthYearText} from "../../Utils/DateFormat";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";
import {updateBilling} from "../../Api/Billing/BillingFetch";
import {Role} from "../../Utils/Role";

const DetailFormBilling = (props) => {
    const [editing, setEditing] = useState({'nsoCode': false, 'sapCode': false});
    const [nsoCode, setNsoCode] = useState('');
    const [sapCode, setSapCode] = useState('');
    const [sensorType, setSensorType] = useState('')
    const [month, setMonth] = useState('')
    const [role, setRole] = useState()

    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    //set sensor type and month-year string
    useEffect(() => {
        setSensorType('FSL ' + props.billingDetail.sensor_type)
        setMonth(getMonthYearText(props.billingDetail.year, props.billingDetail.month))
        setNsoCode(props.billingDetail?.nso_code)
        setSapCode(props.billingDetail?.sap_id)
    }, [props.billingDetail]);

    //Handle Edit nso_code and sap_id
    const handleEditRifOrder = (editField) => {
        setEditing(edit => {return {...edit, [editField]: true};});
    };

    //Confirm change nso_code and sap_id
    const handleConfirmRifOrder = (editField) => {
        // Handle the confirmation logic here, e.g., update the value in the database
        editField === 'nsoCode'
            ? updateBilling(props.billingDetail.id,{'nso_code': nsoCode}, props.setErrors, props.setMessage, props.setIsLoading)
            : updateBilling(props.billingDetail.id,{'sap_id': sapCode}, props.setErrors, props.setMessage, props.setIsLoading);
        setEditing(edit => {return {...edit, [editField]: false};});
    };

    //Confirm delete nso_code and sap_id
    const handleDeleteRifOrder = (editField) => {
        // Handle the deletion logic here
        editField === 'nsoCode'
            ? setNsoCode(props.billingDetail ? props.billingDetail?.nso_code : '')
            : setSapCode(props.billingDetail ? props.billingDetail?.sap_id : '');

        setEditing(edit => {return {...edit, [editField]: false};});
    };

    return (
        <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0 my-2'}>
            <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                <Row>
                    <Col md={8}>
                        <p className={'mb-0 py-2'}>Informazioni Fattura - {props.billingDetail?.billing_type === 1 ? 'Quantità' : 'Canone'}</p>
                    </Col>

                </Row>
            </Col>

            <Form className={'row py-3 text-start'}>
                <Form.Group className={'my-2'}>
                    <Row>
                        <Form.Label className={'col-md-1'}>Payer</Form.Label>
                        <Col className={'col-md-3'}>
                            <Form.Control type="text" name={'payer_name'} readOnly={true} defaultValue={props.billingDetail && props.billingDetail.payer} />
                        </Col>

                        <Form.Label className={'col-md-1'}>Distretto</Form.Label>
                        <Col className={'col-md-3'}>
                            <Form.Control type="text" name={'district_name'} readOnly={true} defaultValue={props.billingDetail && props.billingDetail.district} />
                        </Col>

                        <Form.Label className={'col-md-1'}>Mese-Anno</Form.Label>
                        <Col className={'col-md-3'}>
                            <Form.Control type="text" name={'month-year'} readOnly={true} defaultValue={month} />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className={'my-2'}>
                    <Row>
                        <Form.Label className={'col-md-1'}>Codice NSO</Form.Label>
                        <Col className={'col-md-3 '}>
                            <InputGroup>
                                <Form.Control type={'text'} name={'nso_code'} readOnly={!editing.nsoCode} value={nsoCode} onChange={(e) => setNsoCode(e.target.value)}></Form.Control>
                                {!!role && !['data_entry', 'support', 'single_user', 'referente_aziendale'].includes(role) && (
                                    editing.nsoCode ? (
                                        <>
                                            <InputGroup.Text onClick={() => handleConfirmRifOrder('nsoCode')} title={'Conferma'}>
                                                <AiOutlineCheck color={'green'}/>
                                            </InputGroup.Text>
                                            <InputGroup.Text onClick={() => handleDeleteRifOrder('nsoCode')} title={'Annulla'}>
                                                <AiOutlineClose color={'red'}/>
                                            </InputGroup.Text>
                                        </>
                                    ) : (
                                        <InputGroup.Text onClick={() => handleEditRifOrder('nsoCode')} title={'Modifica'}>
                                            <AiOutlineEdit />
                                        </InputGroup.Text>
                                    )
                                )}
                            </InputGroup>
                        </Col>

                        <Form.Label className={'col-md-1'}>ID SAP/BPCS</Form.Label>
                        <Col className={'col-md-3 '}>
                            <InputGroup>
                                <Form.Control type={'text'} name={'sap_code'} readOnly={!editing.sapCode} value={sapCode} onChange={(e) => setSapCode(e.target.value)}></Form.Control>
                                {!!role && !['data_entry', 'support', 'single_user', 'referente_aziendale'].includes(role) && (
                                    editing.sapCode ? (
                                        <>
                                            <InputGroup.Text onClick={() => handleConfirmRifOrder('sapCode')} title={'Conferma'}>
                                                <AiOutlineCheck color={'green'}/>
                                            </InputGroup.Text>
                                            <InputGroup.Text onClick={() => handleDeleteRifOrder('sapCode')} title={'Annulla'}>
                                                <AiOutlineClose color={'red'}/>
                                            </InputGroup.Text>
                                        </>
                                    ) : (
                                        <InputGroup.Text onClick={() => handleEditRifOrder('sapCode')} title={'Modifica'}>
                                            <AiOutlineEdit />
                                        </InputGroup.Text>
                                    )
                                )}
                            </InputGroup>
                        </Col>

                        <Form.Label className={'col-md-1'}>Tipologia Sensore</Form.Label>
                        <Col className={'col-md-3'}>
                            <Form.Control type="text" name={'sensor_type'} readOnly={true} defaultValue={sensorType} />
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Row>
    )
}

export default DetailFormBilling