import SelectPatient from "../../Components/Orders/SelectPatient";
import NewOrder from "../../Components/Orders/NewOrder";
import {Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {retrievePatientDetail} from "../../Api/Patients/PatientsFetch";
import {generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import SearchPatient from "../../Components/Orders/SearchPatient";
import {retrieveOrderDetail} from "../../Api/Orders/OrdersFetch";
import {TailSpin} from "react-loader-spinner";

const OrderEdit = () => {
    const [patientDetail, setPatientDetail] = useState('');
    const [orderDetail, setOrderDetail] = useState();
    const [errors, setErrors] = useState();
    const [pageUrl, setPageUrl] = useState('/api/orders/v1/')
    const [loading, setIsLoading] = useState(true);
    const {id} = useParams()
    console.log(patientDetail)

    useEffect(() => {
        if (id) {
            console.log('ok')
            retrieveOrderDetail({setOrderDetail, setIsLoading, pageUrl, id, setErrors})
            console.log(orderDetail)
            // setPatientDetail(orderDetail.patient)
        } else {

        }
    }, [id])


    return (
        <>
            <Row className={'mt-4 mx-0'}>


                <>
                    <SelectPatient orderDetail={orderDetail} patientDetail={patientDetail}></SelectPatient>
                    <NewOrder orderDetail={orderDetail} patientId={orderDetail && orderDetail.patient.id}
                              payerId={orderDetail && orderDetail.patient.payer_id}/>
                </>

            </Row>
            {
                loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default OrderEdit