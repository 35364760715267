import React from 'react';
import logoAbbott from '../assets/img/abbott.png'
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

const ErrorFallback = () => {
    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
            <Card className={'align-items-center text-center'}>
                <Card.Body>
                    <Card.Title className={'m-2'}>Oops! Qualcosa è andato storto</Card.Title>
                    <Card.Text className={'m-4'}>
                        <div className={'bg-dark p-3'}>
                            <h3 className={'text-white'}>FreeStyle Libre Desk</h3>
                        </div>
                        <br/>
                        <p>Stiamo lavorando per risolvere il problema!</p>
                        <Button variant="primary" onClick={handleRefresh}>Aggiorna la pagina</Button>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ErrorFallback;
