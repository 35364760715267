import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import '../../assets/css/Patients/Patients.css'
import {BiEraser} from 'react-icons/bi'
import IconTooltip from "../Layout/IconsTooltip";
import {retrievePayers} from "../../Api/Payer/PayersFetch";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {DateInput} from "../DateInput";
import {Grouped} from "../../Utils/Grouped";
import authContext from "../../Contexts/authContext";

const SearchOrderssForm = (props) => {
    const [payersData, setPayersData] = useState()
    const [errors, setErrors] = useState()
    const [groupedPayers, setGroupedPayers] = useState();
    const [resetDate, setResetDate] = useState(false);
    const {isMultipayer} = useContext(authContext)

    //get payers
    useEffect(() => {
        if(isMultipayer){
            retrievePayers({setPayersData, setErrors})
        }
    }, [isMultipayer]);

    const clearInput = () => {
        props.setSearch({})
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }

        setResetDate(true)
    }

    //grouped payer
    useEffect(() => {
        setGroupedPayers(Grouped(payersData, 'region'))
    }, [payersData])

    const handleSearch = (e) => {
        clearInputNameRandomString()
        setResetDate(false)
        props.setSearch({})
        props.setPageUrl('/api/orders/v1?page=1')
        e.preventDefault();

        for (let i of document.querySelectorAll('input')) {
            if (i.value != '') {
                const name = i.getAttribute('name')
                let value = i.value;

                if(process.env.REACT_APP_ENVIRONMENT === 'staging'){
                    value = i.value > 1000000 ? i.value - 1000000 : i.value;
                }

                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
        for (let i of document.querySelectorAll('form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name')
                const value = i.value === '0' ? '%22' + i.value + '%22' : i.value

                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
        generateRandomStringToAddInputName()
    }

    return (
        <>
            <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
                <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={8}>
                            <p className={'mb-0 py-2'}>Ricerca Ordine</p>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-end align-items-center position-relative'}>


                            <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                                <BiEraser className={'fs-3 text-second d-block'}></BiEraser>
                            </IconTooltip>
                        </Col>
                    </Row>

                </Col>


                <Form className={'row py-3 text-start'} id={'searchForm'}>
                    <Form.Group className={'col-md-12 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Nome, Cognome, Codice Fiscale, ID paziente</Form.Label>
                            <Col className={'col-md-10'}>
                                <Form.Control type="text" name={'patients'} className={'d-inline form-control-sm w-100'}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    {isMultipayer && (
                        <Form.Group className={'col-md-4 my-2'}>
                            <Row>
                                <Form.Label className={'col-md-3'}>Asl di appartenenza</Form.Label>
                                <Col className={'col-md-8 '}>
                                    <Form.Select name={'filters[payer_id]'} className={'d-inline form-control-sm'}>
                                        <option readOnly={'readonly'} value={''}>Tutte</option>
                                        {groupedPayers && Object.keys(groupedPayers).map((region) => {
                                            return (
                                                <optgroup key={region} label={region}>
                                                    {groupedPayers[region]?.map(payer => ( // Using optional chaining to handle undefined or null
                                                        <option key={payer.id} value={payer.id}>{payer.title}</option>
                                                    ))}
                                                </optgroup>
                                            )
                                        })}
                                    </Form.Select>

                                </Col>
                            </Row>
                    </Form.Group>   )
                    }

                    <Form.Group className={'col-md-4 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-3'}>Stato dell'ordine</Form.Label>
                            <Col className={'col-md-8 '}>
                                <Form.Select type="text" name={'filters[status_id]'} className={'d-inline form-control-sm'}>
                                    <option value={''}>Tutti</option>
                                    <option value={'0'}>In Attesa</option>
                                    <option value={'1'}>Approvato</option>
                                    <option value={'2'}>Rifiutato</option>
                                    <option value={'3'}>Bloccato</option>
                                    <option value={'4'}>Sospeso</option>
                                    <option value={'5'}>Sostituito</option>
                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>
                    <Form.Group className={'col-md-4 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-3'}>ID ordine</Form.Label>
                            <Col className={'col-md-8 '}>
                                <Form.Control type="text" name={'filters[id]'} className={'d-inline form-control-sm'}
                                />
                            </Col>
                        </Row>

                    </Form.Group>
                    <Form.Group className={'col-md-5 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Data</Form.Label>
                            <Col className={'col-md-5 d-flex '}>
                                <Form.Label className={'col-md-3'}>Dal</Form.Label>
                                <DateInput className={'d-inline form-control form-control-sm'} name={'filters[order_send_timestamp][from]'} reset={resetDate}/>
                            </Col>
                            <Col className={'col-md-5 d-flex'}>
                                <Form.Label className={'col-md-3'}>al</Form.Label>
                                <DateInput className={'d-inline form-control form-control-sm'} name={'filters[order_send_timestamp][to]'} reset={resetDate} />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Row className={'text-center justify-content-center'}>
                        <Col className={'col-md-3'}>
                            <Button variant="primary" onClick={handleSearch} type="submit"
                                    className={'mt-3 rounded-5 px-5  text-uppercase'}>
                                Cerca
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </Row>

        </>

    )
}
export default SearchOrderssForm