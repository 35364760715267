import { useState, useCallback } from 'react';

const generateSessionToken = () => {
    return Math.random().toString(36).substr(2);
};

const useSessionToken = () => {
    const [sessionToken, setSessionToken] = useState(generateSessionToken());

    const refreshSessionToken = useCallback(() => {
        setSessionToken(generateSessionToken());
    }, []);

    return [sessionToken, refreshSessionToken];
};

export default useSessionToken;