import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import '../../assets/css/Patients/Patients.css'
import {BiEraser} from 'react-icons/bi'
import IconTooltip from "../Layout/IconsTooltip";
import {retrievePayers} from "../../Api/Payer/PayersFetch";
import {Role} from "../../Utils/Role";
import {DateInput} from "../DateInput";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {Grouped} from "../../Utils/Grouped";

const SearchDispensationsMassiveForm = (props) => {
    const [payersData, setPayersData] = useState()
    const [errors, setErrors] = useState()
    const [role, setRole] = useState()
    const [groupedPayers, setGroupedPayers] = useState();
    const [resetDate, setResetDate] = useState(false);

    const clearInput = () => {
        props.setSearch({})
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }

        setResetDate(true)
    }

    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)

        if (actualRole && !['data_entry', 'single_user', 'referente_aziendale'].includes(actualRole)) {
            retrievePayers({setPayersData, setErrors})
        }
    }, [])

    //grouped payer
    useEffect(() => {
        setGroupedPayers(Grouped(payersData, 'region'))
    }, [payersData])

    const handleSearch = (e) => {
        clearInputNameRandomString()
        setResetDate(false)
        props.setSearch({})
        e.preventDefault();
        console.log(props.search)
        for (let i of document.querySelectorAll('input:not([type=checkbox])')) {
            if (i.value != '') {
                const name = i.getAttribute('name')
                const value = i.value
                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
        for (let i of document.querySelectorAll('form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name')

                const value = i.value === '0' ? '%22' + i.value + '%22' : i.value
                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
        generateRandomStringToAddInputName()
    }

    return (
        <>
            <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
                <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={8}>
                            <p className={'mb-0 py-2'}>Ricerca Dispensazioni Programmate</p>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-end align-items-center position-relative'}>


                            <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                                <BiEraser className={'fs-3 text-second d-block'}></BiEraser>
                            </IconTooltip>
                        </Col>
                    </Row>

                </Col>


                <Form className={'row py-3 text-start'} id={'searchForm'}>
                    <Form.Group className={'col-md-4 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-3'}>Payer</Form.Label>
                            <Col className={'col-md-8 '}>
                                <Form.Select name={'filters[order.patient.payer_id]'} className={'d-inline form-select-sm'}>
                                    <option readOnly={'readonly'} value={''}>Tutte</option>
                                    {groupedPayers && Object.keys(groupedPayers).map((region) => {
                                        return (
                                            <optgroup key={region} label={region}>
                                                {groupedPayers[region]?.map(payer => ( // Using optional chaining to handle undefined or null
                                                    <option key={payer.id} value={payer.id}>{payer.title}</option>
                                                ))}
                                            </optgroup>
                                        )
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className={'col-md-4 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-3'}>Tipologia sensore</Form.Label>
                            <Col className={'col-md-8 '}>
                                <Form.Select type="text" name={'filters[order.sensor_type]'}
                                             className={'d-inline form-control-sm'}>
                                    <option value={''}>Tutti</option>
                                    <option value={'2'}>FreeStyle libre 2</option>
                                    <option value={'3'}>FreeStyle libre 3</option>

                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>
                    <Form.Group className={'col-md-4 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Data</Form.Label>
                            <Col className={'col-md-5 d-flex '}>
                                <Form.Label className={'col-md-3'}>Dal</Form.Label>
                                <DateInput className={'d-inline form-control form-control-sm w-100'} name={'filters[shipping_at][from]'} reset={resetDate} />
                            </Col>
                            <Col className={'col-md-5 d-flex'}>
                                <Form.Label className={'col-md-3'}>al</Form.Label>
                                <DateInput className={'d-inline form-control form-control-sm w-100'} name={'filters[shipping_at][to]'} reset={resetDate} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className={'col-md-4 offset-md-4 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-3'}>Lettore</Form.Label>
                            <Col className={'col-md-8 '}>
                                <Form.Select type="text" name={'filters[has_reader]'}
                                             className={'d-inline form-control-sm'}>
                                    <option value={''}>Tutti</option>
                                    <option value={1}>Si</option>
                                    <option value={0}>No</option>
                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>


                    <Row className={'text-center justify-content-center'}>
                        <Col className={'col-md-3'}>
                            <Button variant="primary" onClick={handleSearch} type="submit"
                                    className={'mt-3 rounded-5 px-5  text-uppercase'}>
                                Cerca
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </Row>

        </>

    )
}
export default SearchDispensationsMassiveForm