import axiosInstance from "../AxiosInstance";

//recupera lista dei pazienti con filtri e oridnamento
export const retrievePatientsList = ({setPatientsData, setIsLoading, setPagination, perPage, pageUrl, search, filterSort, setTotalRecords, setErrors}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';
    let url = pageUrl + symbol + perPage;

    const query = search ? Object.keys(search)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setPatientsData(response.data.data.data)
        setPagination(response.data.data.links)
        setTotalRecords(response.data.data.total)
        setIsLoading(false)
    }).catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    })
}

//Recupera i dettagli del paziente
export const retrievePatientDetail = ({setPatientDetail, id, setIsLoading}) => {
    axiosInstance.get('/api/patients/v1/' + id).then((response) => {
        setIsLoading(false)
        setPatientDetail(response.data.data)
    }).catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            console.error('Error:', error);
        }
    })
}

export const retrievePatientDetailOrders = ({setOrdersList, id, setIsLoading}) => {
    axiosInstance.get('/api/patients/v1/' + id+'/orders').then((response) => {
        setIsLoading(false)
        console.log(response.data)
        setOrdersList(response.data.data)
    }).catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            console.error('Error:', error);
        }
    })
}

//Aggiunge un paziente
export const addPatient = ({formData, setErrors, setIsLoading, setMessage, navigate, redirect}) => {
    setIsLoading(true)

    axiosInstance.post('/api/patients/v1/', formData).then((response) => {
        setMessage(response.data.message)

        setTimeout(function () {
            setIsLoading(false)
            navigate(redirect + response.data.data.id)
        }, 1500)
    }).catch((error) => {
        setTimeout(function () {
            setIsLoading(false)
        }, 500)

        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

//modifica i dati di un paziente
export const editPatient = ({id, formData, setErrors, setMessage, setEditMode, setIsLoading}) => {
    setIsLoading(true)
    axiosInstance.put('/api/patients/v1/' + id, formData, {
        'headers': {
            "content-type": 'application/x-www-form-urlencoded',
        }
    }).then((response) => {
        setMessage(response.data.message)
        setEditMode(false)
        setIsLoading(false)
        setErrors({})
        setTimeout(function () {
            setMessage('')
        }, 2000)
    }).catch((error) => {
        setIsLoading(false)
        setEditMode(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

/*Autocomplete paziente per Ricerca ordini */
export const retrievePatientAutocomplete = ({setPatientsData,  patientSearch, pageUrl, filterSort, setTotalRecords, setErrors}) => {

    //    let url =pageUrl+'?filters%5Bfirst_name%5D='+patientSearch+'&filters%5Blast_name%5D='+patientSearch+'&search_type=1'
    let url =pageUrl+'?q='+patientSearch
    console.log('autocompleteUrl', url)

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log(response)
        setPatientsData(response.data.data.data)

        // setIsLoading(false)
    }).catch((error) => {
        // setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            console.error('Error:', error);
        }
    })
}

//api call for check if patient have a pending order
export const checkPendingOrder = (patientID, {setHasPendingOrder, setIsLoading}) => {
    setIsLoading(true)

    axiosInstance.get('/api/patients/v1/' + patientID + '/hasPendingOrder').then((response) => {
        // Handle successful response (status code 200)
        setIsLoading(false)
        setHasPendingOrder(response.data.data.has_pending_order);
    }).catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            console.error('Error:', error);
        }
    })
}

//export list of patient in excel
export const exportPatientList = (formData, {setIsLoading, setErrors, setMessage}) => {
    setIsLoading(true)

    axiosInstance.post('/api/patients/v1/export/redirect',
        formData
    ).then((response) => {
        window.location.href = response.data.data.url;
        setMessage(response.data.message)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            console.log(error)
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.error('Error:', error);
        }
    });
}

//block or unlock patient
export const blockUnlockPatient = (data, patientID, action, setMessage, setErrors, setIsLoading) => {
    setIsLoading(true)
    const label = action === 'block' ? 'bloccato' : 'sbloccato';

    axiosInstance.post('/api/patients/v1/' + patientID + '/' + action, data).then((response) => {
        // Handle successful response (status code 200)
        setIsLoading(false)
        setMessage('Paziente ' + label + ' correttamente!')
    }).catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors('Errore ' + label + ' paziente!')
            console.error('Error:', error);
        }
    })
}