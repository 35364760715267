import React, {useEffect, useState} from "react";
import {Alert, Row} from "react-bootstrap";
import CustomTable from "../../Components/Table/CustomTable";
import {TailSpin} from "react-loader-spinner";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import SearchDispensationsMassiveForm from "../../Components/Dispensations/SearchFormMassivePlan";
import DatePlanForm from "../../Components/Dispensations/DatePlanForm";
import {downloadPlannedDispensations, retrievePlannedList, updateShippingAt} from "../../Api/Dispensations/DispensationsFetch";
import {emptyError, handleErrors} from "../../Components/Errors";
import {ConfirmationModal} from "../../Components/Modal/ConfirmationModal";

const DispensationsMassiveList = () => {
    const [massiveDispensationsList, setMassiveDispensationList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/api/dispensations/v1/planned');
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [errors, setErrors] = useState();
    const [message, setMessage] = useState();
    const [loading, setIsLoading] = useState(false)
    const [filterSort, setFilterSort] = useState('')
    const [records, setTotalRecords] = useState()
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [eventTarget, setEventTarget] = useState(null);
    const [actionMessage, setActionMessage] = useState('Sicuro di voler ripianificare queste dispensazioni?')

    useEffect(() => {
        retrievePlannedList({setMassiveDispensationList, pageUrl, search, perPage, filterSort, setIsLoading, setTotalRecords, setPagination})
        setStatusUpdated(false)
    }, [perPage, filterSort, pageUrl, search, statusUpdated])

    useEffect(() => {
        generateRandomStringToAddInputName()
    }, []);

    useEffect(() => {
        if (errors) {
            console.log("errors:", errors);
            handleErrors(errors)
        }
    }, [errors])

    //export table
    const handleExportTable = (e) => {
        clearInputNameRandomString()
        downloadPlannedDispensations({setMessage, setErrors, setIsLoading})
        generateRandomStringToAddInputName()
    }

    //control close of modal
    const handleClose = () => {
        setShowModal(false);
        setActionMessage('')
    }

    //control show of modal and save event target
    const handleShow = (event) => {
        event.preventDefault()
        setShowModal(true);
        setEventTarget(event);
        setActionMessage('Sicuro di voler ripianificare queste dispensazioni?')
    };

    //control action of modal
    const handleAction = (e) => {
        if (eventTarget) {
            handleMassiveUpdateShippingAt(eventTarget)
        }

        setShowModal(false)
    }

    // Function update status
    const handleMassiveUpdateShippingAt = (e) => {
        clearInputNameRandomString()
        const shipping_at = document.querySelector('input[name="shipping_at"]').value;
        const dispensation_ids = [];

        document.querySelectorAll('input[type=checkbox][data-select=allToselect]:checked').forEach((i) => {
            dispensation_ids.push(i.getAttribute('data-id'))
        })

        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')
        if (!emptyfields) {
            updateShippingAt(dispensation_ids, shipping_at, {setMessage, setErrors, setIsLoading})

            setTimeout(() => {
                setStatusUpdated(true)
            },500)
        }

        generateRandomStringToAddInputName()
    }

    const handleSelectAll = (e) => {
       const checked = e.currentTarget.checked

        if (checked) {
            for (let i of document.querySelectorAll('input[type=checkbox][data-select=allToselect]')) {
                i.checked = true
            }
        } else {
            for (let i of document.querySelectorAll('input[type=checkbox][data-select=allToselect]')) {
                i.checked = false
            }
        }
    }

    return (
        <>

            <Row className={'container-fluid w-100 mx-0 pt-5'}>
                <SearchDispensationsMassiveForm setSearch={setSearch} search={search} setPageUrl={setPageUrl}></SearchDispensationsMassiveForm>

                {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

                <ConfirmationModal show={showModal} handleClose={handleClose} handleConfirm={handleAction} actionMessage={actionMessage} />
                <CustomTable massiveDispensationsList={massiveDispensationsList} pagination={pagination}
                             setPerPage={setPerPage}
                             perPage={perPage} setPageUrl={setPageUrl} setFilterSort={setFilterSort}
                             filterSort={filterSort} records={records} handleExportTable={handleExportTable}
                             handleSelectAll={handleSelectAll}></CustomTable>

                {massiveDispensationsList && (
                    massiveDispensationsList.length > 0 ? (
                        <DatePlanForm handleMassiveUpdateShippingAt={handleShow}></DatePlanForm>
                    ) : ''
                )}
            </Row>

            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>

    )
}

export default DispensationsMassiveList