import {Col, Form, Row} from "react-bootstrap";
import IconTooltip from "../../Layout/IconsTooltip";
import {AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit} from "react-icons/ai";
import React, {useEffect, useState} from "react";
import Autocomplete from "../../Layout/Autocomplete";
import {formatId} from "../../../Utils/FormatId";

const PatientsInfo = (props) => {
    const [patientDetail, setPatientDetail] = useState()

    useEffect(() => {
        setPatientDetail(props.orderDetail && props.orderDetail.patient ? props.orderDetail.patient : '')
    }, [props]);

    return (
        <>
            <Row className={'rounded-3 fs-6 px-0 searchForm_container mx-0 box-2 d-flex align-content-start mb-2'}>
                <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={8}>
                            <p className={'mb-0 py-2'}>Informazioni Paziente</p>
                        </Col>
                        <Col md={4}
                             className={'d-flex justify-content-end align-items-center position-relative'}>

                        </Col>
                    </Row>

                </Col>
                <Row className={'py-4 px-4 '}>
                    <Col md={4}><Form.Group className={'my-2'}>
                        <Row className={'d-flex  align-items-center '}>
                            <Form.Label className={'col-md-3'}>Id paziente</Form.Label>
                        <Col className={'col-md-9'}>
                                <Form.Control type={'text'} name={'patient.id'} readOnly={'readonly'}  defaultValue={patientDetail && formatId(patientDetail.id)} ></Form.Control>

                            </Col>
                        </Row>

                    </Form.Group></Col>
                    <Col md={4}>
                        <Form.Group className={'my-2'}>
                            <Row className={'d-flex  align-items-center '}>
                                <Form.Label className={'col-md-3'}>Cognome</Form.Label>

                                <Col className={'col-md-9'}>
                                    <Form.Control type={'text'} name={'patient.last_name'} readOnly={'readonly'} defaultValue={patientDetail && patientDetail.last_name}></Form.Control>

                                </Col>
                            </Row>

                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className={'my-2'}>
                            <Row className={'d-flex  align-items-center '}>
                                <Form.Label className={'col-md-3'}>Nome</Form.Label>

                                <Col className={'col-md-9'}>
                                    <Form.Control type={'text'} name={'patient.first_name'} readOnly={'readonly'} defaultValue={patientDetail && patientDetail.first_name} ></Form.Control>

                                </Col>
                            </Row>

                        </Form.Group>
                    </Col>
                    {patientDetail && patientDetail.shipping_address && (
                        <>
                            <Col md={12}>
                                <Form.Group className={'my-2'}>
                                    <Row className={'d-flex  align-items-center '}>
                                        <Form.Label className={'col-md-1'}>Indirizzo di spedizione</Form.Label>

                                        <Col className={'col-md-11'}>
                                            <Form.Control type={'text'} name={'patient.shipping_address'} readOnly={'readonly'} defaultValue={patientDetail && patientDetail.shipping_address && ( patientDetail.shipping_address.address + ', ' + patientDetail.shipping_address.cap + ', ' + patientDetail.shipping_address.city + ' (' + (patientDetail.shipping_address.province ?? '') + '), ' + patientDetail.shipping_address.region) } ></Form.Control>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className={'my-2'}>
                                    <Row className={'d-flex  align-items-center '}>
                                        <Form.Label className={'col-md-1'}>INFO CONSEGNA</Form.Label>

                                        <Col className={'col-md-11'}>
                                            <Form.Control type={'text'} name={'patient.shipping_address.address_two'} readOnly={'readonly'} defaultValue={patientDetail && patientDetail.shipping_address && patientDetail.shipping_address.address_two } ></Form.Control>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </>
                    )}
                </Row>
            </Row>
        </>
    )

}

export default PatientsInfo