import axiosInstance from "../AxiosInstance";

//lista distretti per paziente
export const retrievePayerAndDistricts = (payer_id, {setPayersData, setIsLoading, setErrors}, id = null, all = '') => {
    let filter = payer_id ? '?filters[payer_id]=' + payer_id : '';
    if(payer_id && id) filter += '&filters[patient_id]=' + id; else if(!payer_id && id) filter += '?filters[patient_id]=' + id;



    axiosInstance.get('/api/districts/v1/dropdown' + all + filter).then((response) => {
        // Handle the 200 response (successfull response)
        setPayersData(response.data.data)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}
