//grouped object
export const Grouped = (objects, field) => {
    return objects?.reduce((acc, object) => {
        const region = object[field]; // Access field dynamically
        if (!acc[region]) {
            acc[region] = [];
        }

        acc[region].push(object);
        return acc;
    }, {}); // Provide an initial empty object for the accumulator
};
