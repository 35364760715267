import {Col, Form, InputGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";
import {Role} from "../../../Utils/Role";
import {retrievePayers} from "../../../Api/Payer/PayersFetch";

const GeneralInfo = (props) => {
    const sensorType = ['Sensore FreeStyle Libre 1', 'Sensore FreeStyle Libre 2', 'Sensore FreeStyle Libre 3'];
    const fornitureType = ['Solo Sensori', 'Fornitura Completa'];
    const dispensingFrequency = {'3':'Trimestrale', '6': 'Semestrale'}
    const [editing, setEditing] = useState(false);
    const [rifOrder, setRifOrder] = useState();
    const [role, setRole] = useState();

    //setRifOrder
    useEffect(() => {
        if (props.orderDetail) {
            setRifOrder(props.orderDetail.id_ordine);
        }
    }, [props.orderDetail]);

    //setRole
    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    //Handle Edit Rif Order
    const handleEditRifOrder = () => {
        setEditing(true);
    };

    //Confirm change rif order
    const handleConfirmRifOrder = () => {
        // Handle the confirmation logic here, e.g., update the value in the database
        setEditing(false);
        props.handleRifOrderChange(rifOrder)
    };

    //Confirm delete rif order
    const handleDeleteRifOrder = () => {
        // Handle the deletion logic here
        setRifOrder(props.orderDetail ? props.orderDetail.id_ordine : '');
        setEditing(false);
    };

    return (
        <Row className={'rounded-3 fs-6 px-0 searchForm_container mx-0 res_box box-1 d-flex align-content-start'}>

            <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                <Row>
                    <Col md={8}>
                        <p className={'mb-0 py-2'}>Informazioni Generali</p>
                    </Col>
                    <Col md={4}
                         className={'d-flex justify-content-end align-items-center position-relative'}>
                    </Col>
                </Row>
            </Col>

            <Row className={'py-4 px-4'}>
                <Form.Group className={'my-2'}>
                    <Row>
                        <Form.Control type={'hidden'} name={'payer_id'} value={'1'}></Form.Control>
                        <Form.Control type={'hidden'} name={'id'} value={props.id}></Form.Control>

                        <Form.Label className={'col-md-3'}>RIF ORDINE</Form.Label>
                        <Col className={'col-md-9 '}>
                            <InputGroup>
                                    <Form.Control type={'text'} name={'id_ordine'} readOnly={!editing} value={rifOrder} onChange={(e) => setRifOrder(e.target.value)}></Form.Control>
                                    {
                                        role && !['data_entry', 'support'].includes(role) && (
                                            editing ? (
                                                <>
                                                    <InputGroup.Text onClick={handleConfirmRifOrder} title={'Conferma'}>
                                                        <AiOutlineCheck color={'green'}/>
                                                    </InputGroup.Text>
                                                    <InputGroup.Text onClick={handleDeleteRifOrder} title={'Annulla'}>
                                                        <AiOutlineClose color={'red'}/>
                                                    </InputGroup.Text>
                                                </>
                                            ) : (
                                                <InputGroup.Text onClick={handleEditRifOrder} title={'Modifica'}>
                                                    <AiOutlineEdit />
                                                </InputGroup.Text>
                                            )
                                        )
                                    }
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className={'my-2'}>
                    <Row>
                        <Form.Label className={'col-md-3'}>Distretto</Form.Label>
                        <Col className={'col-md-9 '}>
                            <Form.Control type="text" name={'patient.district_title'} readOnly={true} defaultValue={props.orderDetail && props.orderDetail.patient ? props.orderDetail.patient.district_title : ''} />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className={' my-2'}>
                    <Row>
                        <Form.Label className={'col-md-3'}>Tipologia sensore</Form.Label>
                        <Col className={'col-md-9 '}>
                            <Form.Control type="text" name={'sensor_type'} readOnly={true} defaultValue={props.orderDetail ? sensorType[props.orderDetail.sensor_type - 1] : ''} />
                        </Col>
                    </Row>

                </Form.Group>

                <Form.Group className={'my-2'}>
                    <Row>
                        <Form.Label className={'col-md-3'}>Fornitura Completa</Form.Label>
                        <Col className={'col-md-9 '}>
                            <Form.Control type="text" readOnly={true} name={'forniture_type'} defaultValue={props.orderDetail ? fornitureType[props.orderDetail.forniture_type] : ''} />
                        </Col>
                    </Row>

                </Form.Group>

                <Form.Group className={' my-2'}>
                    <Row>
                        <Form.Label className={'col-md-3'}>Frequenza dispensazioni</Form.Label>
                        <Col className={'col-md-9 '}>
                            <Form.Control type="text" readOnly={true} name={'dispensing_frequency'} defaultValue={
                                props.orderDetail ? dispensingFrequency[props.orderDetail.dispensing_frequency] : ''
                            }
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Row>
    )
}

export default GeneralInfo