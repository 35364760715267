import {Alert, Button, Modal} from "react-bootstrap";
import {TailSpin} from "react-loader-spinner";
import React, {useEffect, useState} from "react";
import {formatId} from "../../Utils/FormatId";
import {getTrackingList} from "../../Api/Dispensations/DispensationsFetch";
import {formatDataIt} from "../../Utils/DateFormat";

const TrackingModal = ({ show, handleClose, dispensationId }) => {
    const [errors, setErrors] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setIsLoading] = useState(false)
    const [trackingList, setTrackingList] = useState([])

    useEffect(() => {
        if(show === true){
            getTrackingList(dispensationId, {setTrackingList, setIsLoading, setErrors})
        }
    }, [show, dispensationId]);

    return (
        <Modal show={show} onHide={handleClose} className={'modal-lg'} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Tracker Dispensazione {formatId(dispensationId)}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {message && <Alert variant={'success'} onClick={() => {setMessage(null)}} dismissible> {message}</Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null)}} dismissible> {errors} </Alert>}

                <div className={'table-responsive'}>
                    <table className={'table table-bordered'}>
                        <thead>
                            <tr>
                                <th scope="col">Data</th>
                                <th scope="col">Stato</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                trackingList.map((tracking, index) => {
                                    return (
                                        <tr key={index} className={tracking.current === 1 ? 'fw-bold' : ''}>
                                            <td>{formatDataIt(new Date(tracking.status_date.split('T')[0]))}</td>
                                            <td>{tracking.status_it}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>

            <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Chiudi</Button>
            </Modal.Footer>

            {
                loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </Modal>
    );
}

export default TrackingModal;