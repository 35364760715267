import axiosInstance from "../AxiosInstance";

//lista payers
export const retrievePayers = ({setPayersData, setErrors, pageUrl, perPage, search, filterSort}) => {
    let url  = pageUrl ?? '/api/payers/v1';
    console.log(url);

    if(search){
        const symbol = pageUrl.includes('?') ? '&' : '?';
        url = pageUrl + symbol + perPage;

        const query= search ? Object.keys(search)
            .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
            .join('&') : null;


        const filter = filterSort ? Object.keys(filterSort)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
            .join('&') : null;

        if(query !== null) url += '&' + query;
        if(filter !== null) url += '&' + filter;
    }

    axiosInstance.get(url).then((response) => {
        // Handle the 200 response (successfull response)
        setPayersData(response.data.data)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

//get payer rules
export const getPayerRules = (payerId, {setPayerRules, setErrors}) => {
    axiosInstance.get('/api/payers/v1/rules?payer_id=' + payerId).then((response) => {
        setPayerRules(response.data.data)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

//get payer for diabetica export
export const getPayerDiabetica = ({setPayersData, setErrors}) => {
    axiosInstance.get('/api/payers/v1/list/diabetica').then((response) => {
        setPayersData(response.data.data)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}
