//format data for send to api
export const formatData = (date) => {
    if(date instanceof Date){
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }
}

//format data on it language
export const formatDataIt = (date) => {
    if(date instanceof Date){
        const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month

        return day + '/' + month + '/' + date.getFullYear();
    }
}

export const getMonthYearText = (year, month) => {
    month = parseInt(month)
    // Array containing names of the months in Italian
    var months = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
    ];

    // Check if the month number is valid (between 1 and 12)
    if (month >= 1 && month <= 12) {
        var monthName = months[month - 1]; // Array index starts from 0
        return monthName + ' ' + year;
    } else {
        return "Invalid month number";
    }
}