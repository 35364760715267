import axiosInstance from "../AxiosInstance";

//retrieve list of product
export const retrieveProductsList = ({setProducts}) => {
    axiosInstance.get('api/products/v1').then((response) => {
        // Handle successful response (status code 200)
        console.log(response.data)
        setProducts(response.data.data)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)

            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            console.error('Error:', error);
        }
    })
}

//active or deactive product
export const enableDisableProduct = (sku, is_active, {setMessage, setError}) => {
    axiosInstance.put('api/products/v1/' + sku, {
        'is_active': is_active
    }, {
        'headers': {
            "content-type": 'application/x-www-form-urlencoded',
        }
    }).then((response) => {
        // Handle successful response (status code 200)
        setMessage(response.data.message)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setError(error.response.data.message);
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setError(error.response.data.message);
            console.error('Error:', error);
        }
    })
}