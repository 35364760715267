import axiosInstance from "../AxiosInstance";
import {getFilenameFromContentDisposition} from "../../Utils/GetHeaderFileName";

//retrieve list dispensation send
export const retrieveSendDispensations = ({setSendDispensations, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setSendDispensations(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrieve list dispensation blocked
export const retrieveBlockedDispensations = ({setBlockedDispensations, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setBlockedDispensations(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrieve list dispensation blocked
export const retrievePlannedDispensations = ({setPlannedDispensations, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setPlannedDispensations(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//report returned dispensations
export const retrieveReturnedDispensations = ({setReturnedDispensations, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setReturnedDispensations(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrieve billing dispensation send
export const retrieveBillingDispensations = ({setBillingDispensations, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setBillingDispensations(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrieve expired patients
export const retrieveExpiredPatients = ({setExpiredPatients, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setExpiredPatients(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrieve expiring patients
export const retrieveExpiringPatients = ({setExpiringPatients, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setExpiringPatients(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//export dispensazioni inviate in excel
export const exportSendDispensations = (lastMonths = 12, payerId = '', {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/ordersSent/export?filters[lastMonths]=' + lastMonths + '&filters[payer_id]=' + payerId,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_dispensazioni_inviate.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export blocked dispensations in excel
export const exportBlockedDispensations = (lastMonths = 12, payerId = '', suspended = 0, {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/ordersBlocked/export?filters[lastMonths]=' + lastMonths + '&filters[payer_id]=' + payerId + '&filters[suspended]=' + suspended,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_dispensazioni_bloccate.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export blocked dispensations in excel
export const exportPlannedDispensations = (nextMonths = 12, payerId = '', {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/ordersScheduled/export?filters[nextMonths]=' + nextMonths + '&filters[payer_id]=' + payerId,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_dispensazioni_programmate.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export dispensazioni rese in excel
export const exportReturnedDispensations = (nextMonths = 12, payerId = '', {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/ordersReturned/export?filters[nextMonths]=' + nextMonths + '&filters[payer_id]=' + payerId,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_dispensazioni_rese.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export dispensazioni inviate in excel
export const exportBillingDispensations = (lastMonths = 12, payerId = '', {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/ordersBilling/export?filters[lastMonths]=' + lastMonths + '&filters[payer_id]=' + payerId,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_fatturazione.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export expiring patients in excel
export const exportExpiringPatients = (payerId = '', {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/expiringPatients/export?filters[payer_id]=' + payerId,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_pazienti_in_scadenza.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export expired patients in excel
export const exportExpiredPatients = (payerId = '', {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/expiredPatients/export?filters[payer_id]=' + payerId,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        return res.blob()
    }).then((blob) => {
        const outputFilename = `export_pazienti_non_rinnovati.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}

//export diabetic
export const exportDiabetic = (payerId, month, year, sensorType, {setIsLoading, setErrors}) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_BASE_URL + '/api/reports/v1/diabetica/' + month + '/' + year + '/' + payerId + '/' + sensorType,
        {
            method: "GET",
            responseType: 'blob', // Important,
            headers: {
                "X-JWT-Token": localStorage.getItem('jwt'),
                "Authorization": "Bearer " + localStorage.getItem('sanctum'),
            }
        }
    ).then((res) => {
        const filename = getFilenameFromContentDisposition(res.headers);
        return res.blob().then((blob) => ({ filename, blob }));
    }).then(({ filename, blob }) => {
        const outputFilename = filename ?? `export_lombardia.txt`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {type: 'text/plain'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false)
    }, err => {
        console.log(err)
        setErrors("Errore esportazione. Contatta l'amministratore!")
    });
}