import axiosInstance from "../AxiosInstance";

//api call for forgot data
export const forgotPassword = ({email, setErrors, setSendMail, navigate}) => {
    axiosInstance.post('/api/forgot', {
        email: email,
    }).then((response) => {
        // Handle successful response (status code 200)
        setSendMail(response.status)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    });
}

//api for send password confirmation
export const resetPassword = ({password, passwordConfirmation, token, email, setErrors, navigate}) => {
    axiosInstance.post('/api/reset_password', {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        token: token
    }).then((response) => {
        // Handle successful response (status code 200)
        navigate('/login')
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors({
                'password': error.response.data.message
            })

            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    });
}

//api for update password
export const updatePassword = ({currentPassword, newPassword, passwordConfirmation, setErrors, navigate, setIsLoading, logout}) => {
    setIsLoading(true)

    axiosInstance.post('/api/auth/v1/password/update', {
        current_password: currentPassword,
        new_password: newPassword,
        password_confirmation: passwordConfirmation,
    }).then((response) => {
        // Handle successful response (status code 200)
        setIsLoading(false)
        logout()
    }).catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    });
}
