import ProductsTable from "../../Components/Products/ProductsTable";
import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {retrieveProductsList} from "../../Api/Products/ProductsFetch";

const ProductsOptions = () => {
    const [products, setProducts] = useState()
    console.log(products)
    useEffect(() => {
        retrieveProductsList({setProducts})
    }, [])
        return (
            <>   <Row className={'text-start mt-4'}>
                <h2>Gestione prodotti</h2>
            </Row>
                <ProductsTable products={products}></ProductsTable>
            </>

        )
    }

export default ProductsOptions