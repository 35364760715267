import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getPayerDiabetica} from "../../Api/Payer/PayersFetch";
import {exportDiabetic} from "../../Api/Reports/ReportsFetch";
import {TailSpin} from "react-loader-spinner";

const DiabeticExport = () => {
    const [monthOptions, selectMonthOptions] = useState([]);
    const [payersData, setPayersData] = useState({})
    const [errors, setErrors] = useState()
    const [pageUrl, setPageUrl] = useState('/api/payers/v1?filters[region]=Lombardia')
    const [loading, setIsLoading] = useState(false)

    //generate last 12 month option select
    useEffect(() => {
        const today = new Date();
        const months = [];

        for (let i = 1; i < 13; i++) {
            const prevMonth = new Date(today);
            prevMonth.setMonth(today.getMonth() - i);
            const monthName = prevMonth.toLocaleString('default', { month: 'long' });
            const year = prevMonth.getFullYear();
            months.unshift({ monthNumber: prevMonth.getMonth() + 1, month: monthName, year: year.toString() });
        }

        selectMonthOptions(months.reverse());
        getPayerDiabetica({setPayersData, setErrors})
    }, []);

    //handle export diabetic for payer, month and year
    const handleExport = (e) => {
        e.preventDefault();

        const monthOption = document.getElementById('month');
        const month = monthOption.options[monthOption.selectedIndex].value;
        const year = monthOption.options[monthOption.selectedIndex].getAttribute('data-year');
        const payerId = document.getElementById('payer').value;
        const sensorType = document.getElementById('sensor_type').value;

        exportDiabetic(payerId, month, year, sensorType, {setIsLoading, setErrors})
    }

    return (
        <>
            <Row className={'text-start mt-4'}>
                <h2>Export Lombardia</h2>
            </Row>

            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

            <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
                <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={12}>
                            <p className={'mb-0 py-2'}>Export</p>
                        </Col>
                    </Row>

                </Col>


                <Form className={'row py-3 text-start'} id={'searchForm'}>
                    <Form.Group className={'col-md-4'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Payer</Form.Label>
                            <Col className={'col-md-10 '}>
                                <Form.Select id="payer" name={'payer'} className={'d-inline form-select-sm'}>
                                    {payersData && payersData.length > 0 && payersData.map((payer) => {
                                        return <option value={payer['id']}>{payer['title']}</option>
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className={'col-md-4'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Tipo Sensore</Form.Label>
                            <Col className={'col-md-10 '}>
                                <Form.Select id="sensor_type" name={'sensor_type'} className={'d-inline form-select-sm'}>
                                    <option value={2}>FreeStyleLibre 2</option>
                                    <option value={3}>FreeStyleLibre 3</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className={'col-md-4'}>
                        <Row>
                            <Form.Label className={'col-md-2'}>Mesi</Form.Label>
                            <Col className={'col-md-10 '}>
                                <Form.Select id="month" name={'month'} className={'d-inline form-select-sm'}>
                                    {monthOptions && monthOptions.length > 0 && monthOptions.map((option) => {
                                        return <option value={option['monthNumber']} data-year={option['year']}>{option['month'].toUpperCase() + ' ' + option['year']}</option>
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>


                    <Row className={'text-center justify-content-center'}>
                        <Col className={'col-md-3'}>
                            <Button variant="primary" type="submit" onClick={handleExport} className={'mt-3 rounded-5 px-5  text-uppercase'}>
                                Esporta
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </Row>

            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default DiabeticExport