import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import '../../assets/css/Patients/Patients.css'
import {BiEraser} from 'react-icons/bi'
import IconTooltip from "../Layout/IconsTooltip";

const SearchResendDispensation = (props) => {
    const [incrementId, setIncrementId] = useState(props.incrementId ?? '');

    useEffect(() => {
        setIncrementId(props.incrementId);
    }, [props])

    const clearInput = () => {
        props.setIncrementId('')
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }
    }

    //set increment id to search
    const handleSearch = (e) => {
        props.setIncrementId({})
        e.preventDefault();
        props.setIncrementId(document.getElementById('increment_id').value)
    }

    return (
        <>
            <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
                <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={8}>
                            <p className={'mb-0 py-2'}>Ricerca Dispensazione</p>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-end align-items-center position-relative'}>


                            <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                                <BiEraser className={'fs-3 text-second d-block'}></BiEraser>
                            </IconTooltip>
                        </Col>
                    </Row>

                </Col>


                <Form className={'row py-3 text-start'} id={'searchForm'}>
                    <Form.Group className={'col-md-12 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-1'}>Increment ID</Form.Label>
                            <Col className={'col-md-11 '}>
                                <Form.Control className={'form-control'} type={'text'} id={'increment_id'} name={'increment_id'} value={incrementId} onChange={(e) => setIncrementId(e.target.value)}/>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Row className={'text-center justify-content-center'}>
                        <Col className={'col-md-3'}>
                            <Button variant="primary" onClick={handleSearch} type="submit"
                                    className={'mt-3 rounded-5 px-5  text-uppercase'}>
                                Cerca
                            </Button>
                        </Col>
                    </Row>
                </Form>

            </Row>

        </>

    )
}
export default SearchResendDispensation