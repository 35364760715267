import {Button, Col, Form, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import Autocomplete from "../Layout/Autocomplete";
import {retrievePatientAutocomplete} from "../../Api/Patients/PatientsFetch";
import {useNavigate} from "react-router-dom";

const SearchPatient = (props) => {

    const [patientSearch, setPatientSearch] = useState('')
    const [patientsData, setPatientsData] = useState()
    const [pageUrl, setPageUrl] = useState('/api/patients/v1')
    const navigate = useNavigate()

    useEffect(() => {
        if (patientSearch.length > 2 || !isNaN(parseInt(patientSearch))) {
            retrievePatientAutocomplete({setPatientsData, patientSearch, pageUrl})
        }
    }, [patientSearch])
    const handleOnChange = (e) => {
        setPatientSearch(e.currentTarget.value)
        if (e.currentTarget.value === '') {
            setPatientSearch('')
            setPatientsData('')
        }
    }
    const handleOnSelect = (e) => {
        console.log('change')
        console.log(e.id)
        setPatientSearch('')
        navigate('/inserimento-ordine/' + e.id)
    }
    return (

        <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
            <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                <Row>
                    <Col md={8}>
                        <p className={'mb-0 py-2'}>Ricerca Paziente</p>
                    </Col>

                </Row>

            </Col>


            <Form className={'row py-3 text-start'}>
                <Form.Group className={'col-md-12 my-2'}>
                    <Row>
                        <Col>
                            <Form.Label className={'col-md-2'}>Nome, Cognome, Codice Fiscale, ID paziente</Form.Label>
                            <Autocomplete id={'city_send'} name={'addresses[1][city]'} data-error={'addresses.1.city'}
                                          action={handleOnChange}
                                          selectAction={handleOnSelect}
                                // onChange={props.handleFormDetailChange}
                                          item={patientsData}
                                // onFocusOut={onFocusOut}
                                          patientsData={patientsData}
                                          first_name={'first_name'}
                                          last_name={'last_name'}
                                          address={'residential_address.address'}
                                          id={'id'}
                                          required={true}
                                // disabled={props.patientDetail && 'disabled'}
                                // defaultValue={props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[1].city : ''}

                            >

                            </Autocomplete>

                        </Col>
                    </Row>
                </Form.Group>

                <Row className={'text-center justify-content-center'}>
                    <Col className={'col-md-3'}>
                        <Button variant="primary" type="submit"
                                className={'mt-3 rounded-5 px-5  text-uppercase'}>
                            Cerca
                        </Button>
                    </Col>
                </Row>

            </Form>

        </Row>

    )

}

export default SearchPatient