import {Col, Form, Row} from "react-bootstrap";
import React from "react";
import '../../assets/css/Autocomplete.css'

const Autocomplete = ({
                          item,
                          action,
                          mappingProps,
                          index,
                          selectAction,
                          id,
                          name,
                          required,
                          defaultValue,
                          disabled, dataId, onFocusOut, patientsData, last_name, first_name, address, district
                      }) => {
    return (
        <div className={'position-relative'}>
            <Form.Control className={'d-inline  form-control-sm'} type={'text'}
                          onChange={action} disabled={disabled} defaultValue={defaultValue} id={id} name={name}
                          required={required} data-id={dataId} onBlur={onFocusOut}></Form.Control>
            <div>
                <ul className={'list-group position-absolute autocomplete-list m-0'}>{
                    item && item.length > 0 && item.map(
                        i => {
                            /* Da sistemare - mi prendo gli indirizzi ed i distretti  per stamparli */
                            const addresses = i.residential_address ? i.residential_address.address : ''
                            const asl = i.payer ? i.payer.title : ''

                            console.log(i)
                            return (<a key={i.id} className={'text-decoration-none'} onClick={() => {
                                selectAction(i)
                            }}>
                                <li key={i.id} className={'list-group-item'}> {i[mappingProps]}
                                    {patientsData ? (<>
                                            <span
                                                className={'d-inline-block me-2 smaller fw-bold'}>{i[first_name]} {i[last_name]} - ID {i[id]}</span><br/>
                                            <span>{addresses} - ASL {asl}</span>
                                        </>
                                    ) : ''}
                                </li>
                            </a>)
                        })} </ul>
            </div>
        </div>
    )
}

export default Autocomplete