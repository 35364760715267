import axiosInstance from "../AxiosInstance";

//richiede un ticket
export const addTicket = (formData, {setErrors, setIsLoading, setMessage, setIdTicket}) => {
    setIsLoading(true);

    axiosInstance.post('/api/support/v1/createTicket', formData).then((response) => {
        setIsLoading(false)
        setMessage(response.data.message)
        setIdTicket(response.data.data.id)
        setErrors('')
    }).catch((error) => {
        setIsLoading(false)

        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

//list of categories
export const getCategories = ({setCategories, setErrors, setIsLoading}) => {
    setIsLoading(true);

    axiosInstance.get('/api/support/v1/categories').then((response) => {
        setCategories(response.data.data)
        setIsLoading(false)
        setErrors('')
    }).catch((error) => {
        setIsLoading(false)

        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

//list of priorities
export const getPriorities = ({setPriorities, setErrors, setIsLoading}) => {
    setIsLoading(true);

    axiosInstance.get('/api/support/v1/priorities').then((response) => {
        setPriorities(response.data.data)
        setIsLoading(false)
        setErrors('')
    }).catch((error) => {
        setIsLoading(false)

        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            console.error('Error:', error);
        }
    });
}

//get token for ticket frontend
export const getAuthTokenTicket = () => {
    axiosInstance.get('/api/support/v1/auth').then((response) => {
        localStorage.setItem('jwtTicket', response.data.token)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            console.error('Error:', error);
        }
    })
}