import AddPatientForm from "../../Components/Patients/AddForm";
import {useEffect} from "react";
import {generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";

const AddPatient = () => {
    useEffect(() => {
        generateRandomStringToAddInputName()
    }, []);

    return <AddPatientForm></AddPatientForm>
}

export default AddPatient