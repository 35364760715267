import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import '../../assets/css/Login/Login.css'
import {useNavigate} from "react-router-dom";
import {emptyError, handleErrors} from "../../Components/Errors";
import logoFreestyle from '../../assets/img/Logohorizontal.png'
import logoAbbott from '../../assets/img/abbott.png'
import {forgotPassword, resetPassword} from "../../Api/Auth/ResetFetch";

const ResetPassword = () => {
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const [sendMail, setSendMail] = useState(false);
    let token = params.get('token')
    let email = params.get('email')

    const [errors, setErrors] = useState()

    useEffect(() => {
        console.log(errors)
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    //handle submit for forgot and reset password
    const submitHandler = (e) => {
        e.preventDefault();
        emptyError();
        const emptyfields = document.querySelector('span.error_empty_field')

        if (!emptyfields) {
            const email = document.getElementById('email').value;

            if(token){
                const password = document.getElementById('password').value;
                const passwordConfirmation = document.getElementById('password_confirmation').value;

                resetPassword({password, passwordConfirmation, token, email, setErrors, navigate})
            }else{
                forgotPassword({email, setErrors, setSendMail})
            }
        }
    }

    //check the password validation
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;

        if(newPassword.length >= 8) { document.getElementById('hasMinEightChars').classList.add('text-success') } else { document.getElementById('hasMinEightChars').classList.remove('text-success') }
        if(/[A-Z]/.test(newPassword)) { document.getElementById('hasUppercase').classList.add('text-success') } else { document.getElementById('hasUppercase').classList.remove('text-success') }
        if(/[a-z]/.test(newPassword) && /\d/.test(newPassword)) { document.getElementById('hasLowercaseAndNumber').classList.add('text-success') } else { document.getElementById('hasLowercaseAndNumber').classList.remove('text-success') }
    }

    return (
        <Row className={'w-100 m-0 bgLogin container-full mw-100'}>
            <Row className={' justify-content-center text-center me-auto ms-auto align-content-center '}>
                <Col></Col>
                <Col className={'loginContainer text-white py-5 px-3 rounded-5 shadow'}>
                    <Row>
                        <Col md={6}> <img src={logoFreestyle} className={'img-fluid d-inline w-100'}/></Col>
                        <Col md={6}><img src={logoAbbott} className={'img-fluid d-inline w-100'}/></Col>
                        <Col md={12}> <hr style={{color: 'var(--bs-primary)', margin: '20px auto', borderWidth: '2px'}}/></Col>
                    </Row>

                    {!sendMail && (
                        <Row>
                            <Col className={'col-md-12 '}>
                                <h4>FreeStyle Libre Desk</h4>
                                <p>{token ? 'Inserisci la nuova password' : 'Inserisci la tua email per ripristinare la password'}</p>
                            </Col>

                            <Col className={'col-md-12 '}>
                                    <Form>
                                        {
                                            token ? (
                                                <>
                                                    <Form.Group className={'position-relative'}>
                                                        <Form.Control type="password" id={'password'} onKeyUp={handlePasswordChange}
                                                                      className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                                      placeholder="Password" name={'password'} required={'required'}/>
                                                    </Form.Group>

                                                    <Form.Group className={'position-relative'}>
                                                        <Form.Control type="password" id={'password_confirmation'}
                                                                      className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                                      placeholder="Conferma Password" name={'password_confirmation'} required={'required'}/>
                                                    </Form.Group>

                                                    <Form.Control type={'hidden'} name={'token'} value={token} />
                                                    <Form.Control type={'hidden'} id={'email'} name={'token'} value={email} />
                                                    <br></br>
                                                    <Row>
                                                        <Col></Col>
                                                        <Col><p className={'text-white small text-start'} style={{textJustify: 'distribute-all-lines'}}>
                                                            *la password deve avere: <br></br>
                                                            <span id={'hasMinEightChars'}>- almeno 8 caratteri</span> <br></br>
                                                            <span id={'hasUppercase'}>- un carattere maiuscolo</span> <br></br>
                                                            <span id={'hasLowercaseAndNumber'}>- uno minuscolo e un numero</span>
                                                        </p></Col>
                                                        <Col></Col>
                                                    </Row>

                                                </>
                                            ) : (
                                                <Form.Group className={'position-relative'}>
                                                    <Form.Control type="email" id={'email'}
                                                                  className={'my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white'}
                                                                  placeholder="Email" name={'email'} required={'required'}/>
                                                </Form.Group>
                                            )
                                        }

                                        <Button variant="primary" type="submit"
                                                className={'mt-3 rounded-5 px-5 text-uppercase text-black fw-bold'}
                                                onClick={submitHandler}>
                                            Invia
                                        </Button>
                                    </Form>
                            </Col>
                        </Row>
                    ) || (
                        <Col className={'col-md-12 '}>
                            <h4>FreeStyle Libre Desk</h4>
                            <br></br>
                            <p>Se l'account è presente nei nostri sistemi
                                <br></br>
                                ti sarà inviata una mail all'indirizzo indicato
                                <br></br>
                                contenente il link per il recupero della password
                            </p>
                        </Col>
                    )}
                </Col>
                <Col></Col>
            </Row>

        </Row>

    )
}

export default ResetPassword