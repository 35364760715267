import {Col, Row, Form, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DateInput} from "../../DateInput";
import {retriveReasonSuspended} from "../../../Api/Dispensations/DispensationsFetch";
import {emptyError, handleErrors} from "../../Errors";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../../Utils/BrowserAutocompleteOff";
import useDoubleClickPrevention from "../../../Hooks/useDoubleClickPrevention";
import {blockUnlockPatient} from "../../../Api/Patients/PatientsFetch";
import {ConfirmationModal} from "../../Modal/ConfirmationModal";
import {Role} from "../../../Utils/Role";

const BlockedInfo = (props) => {
    const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
    const [reasons, setReasons] = useState();
    const [reason, setReason] = useState()
    const [showModal, setShowModal] = useState(false);
    const [actionMessage, setActionMessage] = useState('Sei sicuro di voler effettuare questa azione?')
    const [eventTarget, setEventTarget] = useState(null);
    const [role, setRole] = useState()

    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    //get reason of suspended
    useEffect(() => {
        retriveReasonSuspended({setReasons})
        setReason(props?.patientDetail?.info?.suspension_reason_id ?? '')
    }, []);

    //control close of modal
    const handleClose = () => {
        setShowModal(false);
    }

    //control show of modal and save event target
    const handleShow = (event) => {
        event.preventDefault()
        setShowModal(true);
        setEventTarget(event)
    };

    //control action of modal
    const handleAction = (e) => {
        if (eventTarget) {
            handleBlockPatient(eventTarget)
        }

        setShowModal(false)
    }

    //function for block or unlock patients
    const handleBlockPatient = (e) => {
        clearInputNameRandomString()
        emptyError();


        let data = {};
        const emptyfields = document.querySelector('span.error_empty_field')

        if (!emptyfields) {
            preventDoubleClick()
            const dataBlocked = document.querySelector("[name='blocked_date']").value;
            const action = e.target.getAttribute('data-action')

            if(action === 'block'){
                data = {'suspension_date': dataBlocked, 'suspension_reason': reason}
            }else{
                props.setIsEditBlock(false);
            }

            blockUnlockPatient(data, props.patientDetail.id, action, props.setMessage, props.setErrors, props.setIsLoading)
        }

        generateRandomStringToAddInputName()
    }

    return (
        <Row className={'rounded-3 fs-6 px-0 searchForm_container mx-0 box-4 w-100'}>
            <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                <Row>
                    <Col md={12}>
                        <p className={'mb-0 py-2'}>Informazioni di blocco</p>
                    </Col>
                </Row>
            </Col>

            <Row className={'py-4 px-4'}>
                <Form.Group className={'my-2'}>
                    <Row>
                        <Form.Label className={'col-md-1'}>Motivo Blocco*</Form.Label>
                        <Col className={'col-md-3'}>
                            <Form.Select name={"suspensation_reason_id"} className={'d-inline form-select-sm'} value={reason} disabled={false} data-value={reason} onChange={(e) => setReason(e.target.value)} required={'required'}>
                                <option value={''}>Seleziona</option>
                                {reasons && reasons.map((reason) => {
                                    return <option key={reason.id} value={reason.id}>{reason.reason}</option>
                                })}
                            </Form.Select>
                        </Col>

                        <Form.Label className={'col-md-1'}>Data Blocco*</Form.Label>
                        <Col className={'col-md-3'}>
                            <DateInput className={'d-inline form-control form-control-sm'} name={'blocked_date'} maxDate={new Date()} selectedDate={props?.patientDetail?.info?.suspension_date ? new Date(props?.patientDetail?.info?.suspension_date) : new Date()}/>
                        </Col>

                        <Col md={4}>
                            <Row>
                                <Col md={!props.patientDetail?.info?.suspension_date ? 6 : 12} className={'justify-content-between d-flex'}>
                                    { !!role && !['data_entry'].includes(role) && props.unlocked > 0 && props.patientDetail?.info?.suspension_date && (
                                        <Button type="btn" id="btnBlockPatient" disabled={isButtonDisabled} data-action={'unlock_patient'} className={`rounded-5 border-0 bg-danger btn-sm text-uppercase ${props.unlocked === 2 ? 'w-100 mx-1' : 'col-12'}`} onClick={handleShow}>
                                            Sblocca Paziente
                                        </Button>
                                    )}
                                    { !!role && !['data_entry'].includes(role) && props.unlocked === 2 && props.patientDetail?.info?.suspension_date && (
                                        <Button type="btn" id="btnBlockPatient" disabled={isButtonDisabled} data-action={'unlock'} className={`rounded-5 border-0 bg-danger btn-sm text-uppercase ${props.unlocked === 2 ? 'w-100 mx-1' : 'col-12'}`} onClick={handleShow}>
                                            Sblocca Paziente (Totale)
                                        </Button>
                                    )}
                                    { !!role && !['data_entry'].includes(role) && !props.patientDetail?.info?.suspension_date && (
                                        <Button type="btn" id="btnBlockPatient" disabled={isButtonDisabled} data-action={'block'} className={'rounded-5 border-0 bg-danger btn-sm text-uppercase col'} onClick={handleShow}>
                                            Blocca Paziente
                                        </Button>
                                    )}
                                </Col>
                                {!props.patientDetail?.info?.suspension_date && (<Col md={6}>
                                    <a type="btn" id="btnNoBlockPatient" className={'btn text-white rounded-5 border-0 w-100 bg-secondary btn-sm text-uppercase'} onClick={(e) => props.setIsEditBlock(false)}>
                                        Annulla
                                    </a>
                                </Col> )}
                            </Row>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
            <ConfirmationModal show={showModal} handleClose={handleClose} handleConfirm={handleAction} actionMessage={actionMessage} isButtonDisabled={isButtonDisabled}/>
        </Row>
    )
}

export default BlockedInfo