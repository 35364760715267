//api call to send log data to server
import axiosInstance from "../AxiosInstance";

//send log data to server
export const sendLog = (error, info) => {
    axiosInstance.post('/api/support/v1/log', {
        'severity': 'critical',
        'message': JSON.stringify({ error: error.message, info }),
        'send_email': 1
    }).then((response) => {
        console.log(response.data.message)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            console.error('Error:', error);
        }
    })
}

