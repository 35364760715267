//generate random string
export const generateRandomStringToAddInputName = () => {
    const randomString = '|' + Math.random().toString(36).substr(2, 5);

    document.querySelectorAll('input').forEach((element) => {
        let name = element.getAttribute('name');
        element.setAttribute('name', name + randomString);
        element.setAttribute('autocomplete', 'new-password');
    })
};

//clear name of form of random string
export const clearInputNameRandomString = () => {
    document.querySelectorAll('input').forEach((element) => {
        let name = element.getAttribute('name').split('|');
        element.setAttribute('name', name[0]);
    })
}