import FormDownload from "../../Components/POD/Form";
import {Row} from "react-bootstrap";
import React from "react";

const DownloadPod = () => {
    return (
        <>
            <Row className={'text-start mt-2'}>
                <h2>Download POD</h2>
            </Row>

            <FormDownload></FormDownload>
        </>
    )
}

export default DownloadPod