import React, {useContext, useEffect, useState} from "react";
import CustomTable from "../../Components/Table/CustomTable";
import {Alert, Row} from "react-bootstrap";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {exportSendDispensations, retrieveSendDispensations} from "../../Api/Reports/ReportsFetch";
import {TailSpin} from "react-loader-spinner";
import SearchSendDispensations from "../../Components/Reports/SearchSendDispensations";
import authContext from "../../Contexts/authContext";

const SendDispensations = () => {
    const [sendDispensations, setSendDispensations] = useState([])
    const [pageUrl, setPageUrl] = useState('/api/reports/v1/ordersSent');
    const [search, setSearch] = useState({'filters[lastMonths]': 'T'});
    const [perPage, setPerPage] = useState('per_page=10')
    const [loading, setIsLoading] = useState();
    const [filterSort, setFilterSort] = useState('');
    const [totalRecords, setTotalRecords] = useState();
    const [pagination, setPagination] = useState();
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const {isMultipayer, roleId} = useContext(authContext)

    useEffect(() => {
        retrieveSendDispensations(({setSendDispensations, pageUrl, search, perPage, filterSort, setIsLoading, setTotalRecords, setPagination}))
    }, [perPage, filterSort, pageUrl, search])

    //export reports in excel
    const handleExportTable = (e) => {
        clearInputNameRandomString()
        const lastMonths = document.getElementById('lastMonths').value;
        const payerId = document.getElementById('payerId')?.value;

        if(isMultipayer && payerId == '' && roleId <= 3){
            setErrors('Seleziona un Payer')
        }else{
            setErrors(null)
            exportSendDispensations(lastMonths, payerId, {setErrors, setIsLoading})
        }

        generateRandomStringToAddInputName()
    }


    return (
            <>
                <Row className={'text-start mt-4'}>
                    <h2>Dispensazioni Inviate</h2>
                </Row>

                <SearchSendDispensations setSearch={setSearch} search={search} setPageUrl={setPageUrl}></SearchSendDispensations>

                {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

                <CustomTable sendDispensations={sendDispensations} pagination={pagination}
                             setPerPage={setPerPage} records={totalRecords}
                             perPage={perPage} setPageUrl={setPageUrl} setFilterSort={setFilterSort}
                             filterSort={filterSort} handleExportTable={handleExportTable} />

                {loading &&
                    <TailSpin
                        height="80"
                        width="80"
                        color="var(--primary)"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                        visible={true}/>
                }
            </>
        )
    }

    export default SendDispensations