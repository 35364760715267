import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';
import {useEffect, useState} from "react";
import {formatData} from "../Utils/DateFormat";

export const DateInput = (props) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [dataValue, setDataValue] = useState('')
    const [name, setName] = useState('')
    const [change, setChange] = useState(false)
    const [update, setUpdate] = useState(false)

    //settings disable input with data id != '' and props.disabled = true
    useEffect(() => {
        if(props.disabled === true && !change){
            document.querySelectorAll('div[data-id][data-id]:not([data-id=""]) input[name^="datepicker"]').forEach((el) => {
                el.setAttribute('disabled', 'disabled');
            })
        }
    })

    //reset date if props resetDate is true
    useEffect(() => {
        if(props.reset === true){
            resetDate()
        }
    }, [props])

    //set default value
    useEffect(() => {
        if(!change){
            setSelectedDate(props.selectedDate)
            setName('datepicker*'+props.name)
        }
    }, [props, change]);

    //set input in en format to send
    useEffect(() => {
        setDataValue(formatData(selectedDate));
        setUpdate(false)
    }, [selectedDate])

    //reset date input
    const resetDate = () => {
        setSelectedDate(null); // o qualsiasi valore di default desiderato
        setChange(true); // Se necessario
    }

    //handle blur if not click on a calendar next or preview
    const handleBlur = (e) => {
        if(!update && ((e.relatedTarget && !e.relatedTarget.classList.contains('react-datepicker__navigation')) || (!e.relatedTarget &&e.target.tagName.toLowerCase() === 'input'))){
            props.handleDispensationAction(e);
            setUpdate(true)
        }
    }

    return (
        <div style={{ width: '100%' }} data-id={props.dataId ? props.dataId : ''} onBlur={props.handleDispensationAction ? handleBlur : null}>
            <DatePicker
                className={props.className ? props.className : "form-control form-control-sm"}
                selected={selectedDate}
                onChange={(date) => {
                        setSelectedDate(date)
                        setChange(true)
                    }
                }
                locale={it} // Set the locale to Italian
                dateFormat="dd/MM/yyyy" // Date format for display
                minDate={props.minDate ? props.minDate : null} // Set minimum date
                maxDate={props.maxDate ? props.maxDate : null} // Set maximun date
                wrapperClassName="datePickerWrapper" // Custom class for styling
                style={{ width: '100%' }}
                name={name}// Set width to 100%
                required={props.required ? props.required : false}
            />
            <input type={"hidden"} name={props.name} value={dataValue} data-id={props.dataId ? props.dataId : ''}/>
        </div>
    );
}