import {Navigate, Outlet} from "react-router-dom";
import {Role} from "../Utils/Role";

const ProtectedRoute = ({ isNotAllowed = [], redirectPath = '/home', children }) => {
    if (!!Role() && isNotAllowed.includes(Role())) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;