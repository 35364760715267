import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Layout from "./Components/Layout/Layout";
import {AuthContextProvider} from "./Contexts/authContext";
import { ErrorBoundary } from "react-error-boundary";
import {sendLog} from "./Api/Log/LogFetch";
import Fallback from "./Components/Fallback";
import {useEffect} from "react";

function App() {
    const logError = (error, info) => {
        sendLog(error, info)
    };

    // Set the title of the page
    useEffect(() => {
        document.title = process.env.REACT_APP_TITLE;
    }, []);

    return (
        <ErrorBoundary fallback={<Fallback />} onError={logError}>
            <AuthContextProvider>
                <div className="App ">
                    <Layout />
                </div>
            </AuthContextProvider>
        </ErrorBoundary>
    );
}

export default App;
