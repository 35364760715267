import '../../assets/css/Footer.css'

const Footer = () => {
    return (
        <div className={'shadow footer'}>
            <span className={'text-white'}>©️ 2024 Abbott. L’involucro del sensore, FreeStyle, Libre, e i marchi correlati sono marchi di Abbott.</span>
        </div>
    )
}

export default Footer