//return index selected by option of select
export const getSelectedIndex = (select, value) => {
    console.log(select)
    for (let i = 0; i < select.options.length; i++) {
        if (select.options[i].value === value) {
            return i
        }
    }

    // If the value is not found, you may want to handle this case.
    return false;
}