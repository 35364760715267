import React from "react";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {AiOutlineFileDone, AiOutlineFilePdf} from "react-icons/ai";
import {formatDataIt} from "../../Utils/DateFormat";
import {TbTruckDelivery} from "react-icons/tb";

const TableResendDispensation = (props) => {
    return (
        <Table striped bordered className={'rounded-bottom-2 mb-4'}>
            <thead className={'rounded-2'}>
                <tr className={'rounded-top-2'}>
                    <th className={'bkg-primary text-white'}>ID Dispensazione</th>
                    <th className={'bkg-primary text-white'}>Increment ID<br></br>Entity ID</th>
                    <th className={'bkg-primary text-white'}>Customer ID</th>
                    <th className={'bkg-primary text-white'}>Id Paziente</th>
                    <th className={'bkg-primary text-white'}>Data Dispensazione</th>
                    <th className={'bkg-primary text-white'}>N. Prog</th>
                    <th className={'bkg-primary text-white'}>Numero sensori</th>
                    <th className={'bkg-primary text-white'}>Num. Strisce (x25)</th>
                    <th className={'bkg-primary text-white'}>Num. Lancette (x50)</th>
                    <th className={'bkg-primary text-white'}>Lettore</th>
                    <th className={'bkg-primary text-white'}>Stato dispensazione</th>
                    <th className={'bkg-primary text-white'}>Tracking</th>
                    <th className={'bkg-primary text-white'}>Documenti di consegna</th>
                    <th className={'bkg-primary text-white'}>Azioni</th>
                </tr>
            </thead>
            <tbody>
            <tr className={'align-middle'}>
                <td>{props.dispensation.id}</td>
                <td>{props.dispensation.increment_id}<br></br><span
                    className={'small'}>{props.dispensation.entity_id}</span></td>
                <td>{props.dispensation.order.patient.entity_id}</td>
                <td><Link
                    to={`/dettaglio-pazienti/${props.dispensation.order.patient.id}`}>{props.dispensation.order.patient.id}</Link>
                </td>
                <td>{props.dispensation.shipping_at && formatDataIt(new Date(props.dispensation.shipping_at))}</td>
                <td>{props.dispensation.n_prog}</td>
                <td>{props.dispensation.sensors_quantity}</td>
                <td>{props.dispensation.strips_quantity}</td>
                <td>{props.dispensation.lancets_quantity}</td>
                <td>{props.dispensation.has_reader && props.dispensation.has_reader === true ? 'Si' : 'No'}</td>
                <td>{props.dispensation.status_name}<br></br>{props.dispensation.suspension_reason && '(' + props.dispensation.suspension_reason.reason + ')'}
                </td>
                <td><a href={'#'} onClick={() => {
                    props.setShowModal(true);
                    props.setTrackerDispensationId(props.dispensation.id)
                }}>{props.dispensation.last_tracking}</a></td>
                <td>
                    {props.dispensation.has_merged ? <AiOutlineFileDone onClick={props.handleDownloadPod} data-id={props.dispensation.id} data-type='merged' size={20} title={'POD - Delivery Note'}/> : ''}
                    {!props.dispensation.has_merged && props.dispensation.has_pod ? <AiOutlineFilePdf onClick={props.handleDownloadPod} data-id={props.dispensation.id} data-type='pod' size={20} title={'POD'}/> : ''}
                    {!props.dispensation.has_merged && props.dispensation.has_delivery_note ? <TbTruckDelivery onClick={props.handleDownloadPod} data-id={props.dispensation.id} data-type='delivery_note' size={20} title={'Delivery Note'}/> : ''}
                </td>
                <td></td>
            </tr>
            </tbody>
        </Table>
    )
}

export default TableResendDispensation