import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import Autocomplete from "../Layout/Autocomplete";
import {retrievepatientDetail, retrievePatientsList} from "../../Api/Patients/PatientsFetch";
import {formatId} from "../../Utils/FormatId";


const selectPatient = (props) => {
    console.log(props.patientDetail)
    return (
        <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0'}>
            <Col className={'bkg-primary text-white text-start rounded-top-3 d-flex align-items-center'}>
                <p className={'mb-0 py-2'}>Informazioni paziente</p>
            </Col>

            <Form className={'row py-3 text-start '} readOnly={'readonly'}>
                <Row>
                    <Col md={4}>
                        <Form.Group className={'my-2'}>
                            <Row className={'align-items-center d-flex'}>
                                <Form.Label className={'col-md-2 mb-0'}>Id Paziente</Form.Label>
                                <Col className={'col-md-10 '}>
                                    <Form.Control type={'text'} readOnly={true} name={'id'} value={props.patientDetail ? formatId(props.patientDetail.id) :  props.orderDetail && formatId(props.orderDetail.patient.id)}></Form.Control>

                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className={'my-2'}>
                            <Row className={'align-items-center d-flex'}>
                                <Form.Label className={'col-md-2 mb-0'} >Cognome</Form.Label>
                                <Col className={'col-md-10 '}>
                                    <Form.Control type={'text'} readOnly={true} name={'last_name'}value={props.patientDetail  ? props.patientDetail.last_name : props.orderDetail && props.orderDetail.patient.last_name}></Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className={'my-2'}>
                            <Row className={'align-items-center d-flex'}>
                                <Form.Label className={'col-md-2 mb-0'} >Nome</Form.Label>
                                <Col className={'col-md-10 '}>
                                    <Form.Control type={'text'} readOnly={true} name={'first_name'} value={props.patientDetail ? props.patientDetail.first_name : props.orderDetail && props.orderDetail.patient.first_name}></Form.Control>

                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className={'my-2'}>
                            <Row className={'align-items-center d-flex'}>
                                <Form.Label className={'col-md-2 mb-0'}>Asl</Form.Label>
                                <Col className={'col-md-10 '}>
                                    <Form.Control type={'text'} readOnly={true} name={'district_id'} value={props.orderDetail ? props.orderDetail.patient.district.payer.title : props.patientDetail && props.patientDetail.payer.title}></Form.Control>

                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md={8}>
                        <Form.Group className={'my-2'}>
                            <Row className={'align-items-center d-flex'}>
                                <Form.Label className={'col-md-1 mb-0'} >Indirizzo</Form.Label>
                                <Col className={'col-md-11 '}>
                                    <Form.Control type={'text'} readOnly={true} name={'address'} value={props.patientDetail && props.patientDetail.addresses[0] ? props.patientDetail.addresses[0].address : props.orderDetail && props.orderDetail.patient.residential_address && props.orderDetail.patient.residential_address.address}></Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Row>
    )
}

export default selectPatient