import SearchBilling from "../../Components/Billing/SearchBilling";
import {Alert, Col, Row} from "react-bootstrap";
import {TailSpin} from "react-loader-spinner";
import React, {useEffect, useState} from "react";
import CustomTable from "../../Components/Table/CustomTable";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import SearchBillingFeeQuantity from "../../Components/Billing/SearchBillingFeeQuantity";
import {exportBillingFee, retrieveBillingFeeList, saveFeeList} from "../../Api/Billing/BillingFetch";
import {useNavigate} from "react-router-dom";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";

const AddFeeBilling = () => {
    const [errors, setErrors] = useState();
    const [message, setMessage] = useState();
    const [loading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/api/billing/v1/fee')
    const [perPage, setPerPage] = useState('per_page=100');
    const [search, setSearch] = useState({})
    const [billingFeeList, setBillingFeeList] = useState({})
    const [filterSort, setFilterSort] = useState('')
    const [records, setTotalRecords] = useState()
    const [totalDays, setTotalDays] = useState(0)
    const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
    const navigate = useNavigate()

    useEffect(() => {
        generateRandomStringToAddInputName()
    }, []);

    useEffect(() => {
        if(Object.keys(search).length > 0){
            retrieveBillingFeeList({setBillingFeeList,setIsLoading, setPagination, perPage, pageUrl, search, filterSort, setTotalRecords, setErrors, setTotalDays})
        }
    }, [perPage, filterSort, pageUrl, search])

    //export table
    const handleExport = (type) => {
        clearInputNameRandomString()

        exportBillingFee(search, type, {setIsLoading, setErrors})
        generateRandomStringToAddInputName()
    }

    //save result of search and generate a new billing
    const saveSearchResult = (e) => {
        let data = search;
        data['total'] = totalDays;
        data['filters[documents]'] = data['filters[documents]']?.toString();

        // Iterate through the keys of the object
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                // Check if the key contains "filters"
                if (key.includes("filters")) {
                    // Extract the value associated with the current key
                    const value = data[key]?.toString();
                    // Replace the current key
                    const newKey = key.replace("filters", "").replace(/\[|\]/g, '');
                    // Add a new key-value pair with the new key and the extracted value
                    data[newKey] = value;
                    // Remove the old key
                    delete data[key];
                }
            }
        }

        preventDoubleClick()
        saveFeeList(data, {setErrors, setMessage, setIsLoading, setSearch, navigate})
    }

    return (
        <>
            <Row className={'container-fluid w-100 mx-0 pt-5'}>
                <SearchBillingFeeQuantity setSearch={setSearch} title={'Canone'} setPageUrl={setPageUrl}/>

                {message && <Alert variant={'success'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}

                <CustomTable billingFeeList={billingFeeList} pagination={pagination} setPerPage={setPerPage}
                             perPage={perPage} setPageUrl={setPageUrl} setFilterSort={setFilterSort}
                             filterSort={filterSort} records={records} totalDays={totalDays} handleExport={handleExport}
                             saveSearchResult={saveSearchResult} isButtonDisabled={isButtonDisabled} />
            </Row>

            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default AddFeeBilling