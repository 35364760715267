import {useParams} from "react-router-dom";
import {Alert, Col, Row} from "react-bootstrap";
import {formatId} from "../../Utils/FormatId";
import React, {useEffect, useState} from "react";
import {Role} from "../../Utils/Role";
import {generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {TailSpin} from "react-loader-spinner";
import {
    enableDisableItemBilling,
    exportDetailBillingList,
    getBillingDetailList,
    getDetailBilling,
} from "../../Api/Billing/BillingFetch";
import DetailFormBilling from "../../Components/Billing/DetailFormBilling";
import CustomTable from "../../Components/Table/CustomTable";

const BillingDetail = () => {
    const {id} = useParams()
    const [role, setRole] = useState()
    const [billingDetail, setBillingDetail] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState();
    const [message, setMessage] = useState();
    const [billingFeeList, setBillingFeeList] = useState({})
    const [billingQuantityList, setBillingQuantityList] = useState({})
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/api/billing/v1/invoice/' + id + '/items')
    const [perPage, setPerPage] = useState('per_page=100');
    const [records, setTotalRecords] = useState()
    const [totalDays, setTotalDays] = useState(0)
    const [totalSensor, setTotalSensor] = useState(0)
    const [filterSort, setFilterSort] = useState('')

    //check role
    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    //retrieve patient detail data and order for this patient
    useEffect(() => {
        generateRandomStringToAddInputName()
        getDetailBilling(id, {setBillingDetail, setErrors, setMessage, setIsLoading})
    }, [id])

    //get list of detail billing
    useEffect(() => {
        getBillingDetailList(billingDetail.billing_type, {setBillingFeeList, setBillingQuantityList, pageUrl, perPage, setIsLoading, setTotalRecords, setPagination, setErrors, setTotalDays, setTotalSensor, filterSort})
    }, [billingDetail.billing_type, filterSort, pageUrl, message])

    //export table
    const handleExport = (type) => {
        exportDetailBillingList(id, type, {setIsLoading, setErrors})
    }

    //enable or disable invoice record
    const handleEnableDisable = (item_id, action) => {
        enableDisableItemBilling(id, action, item_id, {setErrors, setMessage, setIsLoading})
    }

    //hide or view trashed elements on invoice
    const handleHideTrashedElement = (e) => {
        setFilterSort(filter => ({
                ...filter, 'only_active': e.target.checked ? 1 : 0
            })
        )
    }

    return (
        <>
            <Row className={'mt-2'}>
                <Col md={6} className={'text-start'}>
                    <h2>Dettaglio fattura - ID {formatId(parseInt(billingDetail?.id))}</h2>
                </Col>
            </Row>

            {message && <Alert variant={'success'} onClick={() => {
                setMessage(null)
            }} dismissible> {message} </Alert>}
            {errors && <Alert variant={'danger'} onClick={() => {
                setErrors(null)
            }} dismissible> {errors} </Alert>}

            <DetailFormBilling billingDetail={billingDetail} setErrors={setErrors} setMessage={setMessage} setIsLoading={setIsLoading}/>

            {billingDetail.billing_type == 1 ? (
                <CustomTable billingQuantityList={billingQuantityList} pagination={pagination} setPerPage={setPerPage}
                             perPage={perPage} setPageUrl={setPageUrl} records={records} totalSensor={totalSensor}
                             filterSort={filterSort} setFilterSort={setFilterSort} handleExport={handleExport} handleEnableDisable={handleEnableDisable}
                             handleHideTrashedElement={handleHideTrashedElement} billingDetail={billingDetail} />

            ) : (
                <CustomTable billingFeeList={billingFeeList} pagination={pagination} setPerPage={setPerPage}
                             perPage={perPage} setPageUrl={setPageUrl} records={records} totalDays={totalDays}
                             filterSort={filterSort} setFilterSort={setFilterSort} handleExport={handleExport} handleEnableDisable={handleEnableDisable}
                             handleHideTrashedElement={handleHideTrashedElement} billingDetail={billingDetail} />
            )}

            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default BillingDetail