import SearchBilling from "../../Components/Billing/SearchBilling";
import {Alert, Row} from "react-bootstrap";
import {TailSpin} from "react-loader-spinner";
import React, {useEffect, useState} from "react";
import CustomTable from "../../Components/Table/CustomTable";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {retrieveBillingList} from "../../Api/Billing/BillingFetch";

const BillingList = () => {
    const [errors, setErrors] = useState();
    const [message, setMessage] = useState();
    const [loading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/api/billing/v1/invoice/list')
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [billingList, setBillingList] = useState({})
    const [filterSort, setFilterSort] = useState('')
    const [records, setTotalRecords] = useState()

    useEffect(() => {
        generateRandomStringToAddInputName()
    }, []);

    useEffect(() => {
        retrieveBillingList({setBillingList,setIsLoading, setPagination, perPage, pageUrl, search, filterSort, setTotalRecords, setErrors})
    }, [perPage, filterSort, pageUrl, search])

    //export table
    const handleExportTable = (e) => {
        clearInputNameRandomString()
        const form = document.getElementById('searchForm');
        const formData = new FormData(form);

        //exportOrderList(formData, {setIsLoading, setErrors})
        generateRandomStringToAddInputName()
    }

    return (
        <>
            <Row className={'container-fluid w-100 mx-0 pt-5'}>
                <SearchBilling setSearch={setSearch} setPageUrl={setPageUrl}/>

                {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
                {errors && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

                <CustomTable billingList={billingList} pagination={pagination} setPerPage={setPerPage}
                             perPage={perPage} setPageUrl={setPageUrl} handleExportTable={handleExportTable}
                             setFilterSort={setFilterSort} filterSort={filterSort} records={records} />
            </Row>

            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default BillingList