import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const ConfirmationModal = ({ show, handleClose, handleConfirm, actionMessage, isButtonDisabled }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Conferma Azione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{actionMessage}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancella
                </Button>
                <Button variant="primary" onClick={handleConfirm} disabled={isButtonDisabled}>
                    Conferma
                </Button>
            </Modal.Footer>
        </Modal>
    );
};