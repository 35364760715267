// Funzione debounce
const debounce = (func, delay) => {
    let timeoutId;
    console.log(timeoutId)
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export default debounce;