import AddPatientForm from "../../Components/Patients/AddForm";
import {Col, Row} from "react-bootstrap";
import CustomTable from "../../Components/Table/CustomTable";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {retrievePatientDetail, retrievePatientDetailOrders} from "../../Api/Patients/PatientsFetch";
import {TailSpin} from "react-loader-spinner";
import {generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {Role} from "../../Utils/Role";
import {formatId} from "../../Utils/FormatId";

const PatientsDetails = () => {
    const {id} = useParams()
    const [patientDetail, setPatientDetail] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [perPage, setPerPage] = useState('per_page=10');
    const [ordersList, setOrdersList] = useState({})
    const [role, setRole] = useState()

    //check role
    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    //retrieve patient detail data and order for this patient
    useEffect(() => {
        generateRandomStringToAddInputName()
        retrievePatientDetail({setPatientDetail, id, setIsLoading});
        retrievePatientDetailOrders({setOrdersList, id, setIsLoading})
    }, [id])


    return (
        <>
            <Row className={'mt-2'}>
                <Col md={6} className={'text-start'}>
                    <h2>Dettaglio paziente - ID {formatId(parseInt(patientDetail.id))}</h2>
                </Col>

                {!!role && !['data_entry', 'support', 'single_user', 'referente_aziendale'].includes(role) && (
                    <Col md={6} className={'text-end'}>
                        <h2>Customer ID - {patientDetail.entity_id}</h2>
                    </Col>
                )}
            </Row>

            <AddPatientForm patientDetail={patientDetail} setPatientDetail={setPatientDetail} id={id}> </AddPatientForm>

            <Row className={'text-start'}>
                <h2>Ultimi Ordini</h2>
            </Row>
            <Row>
                <CustomTable perPage={perPage} ordersDetailList={ordersList}> </CustomTable>
            </Row>
            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }

        </>
    )


}

export default PatientsDetails