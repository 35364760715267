import axiosInstance from "../AxiosInstance";

export const retrieveLoginData = ({login, enteredEmail, enteredPassword, setErrors, navigate}) => {
    axiosInstance.post('/api/auth/v1/login', {
        usernameOrEmail: enteredEmail,
        password: enteredPassword,
    }).then((response) => {
        // Handle successful response (status code 200)
        login(JSON.stringify(response.data.user), response.data.tokens.sanctum, response.data.tokens.jwt, response.data.jwt_decode.exp.date)

        if(sessionStorage.hasOwnProperty('redirect')){
            ['/pazienti-in-scadenza', '/pazienti-non-rinnovati'].includes(sessionStorage.getItem('redirect'))
                ? navigate(sessionStorage.getItem('redirect'))
                : navigate('/lista-pazienti')

            sessionStorage.removeItem('redirect')
        }else{
            navigate('/lista-pazienti')
        }
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
    });
}

