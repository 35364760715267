import {Button, Col, Form, Row} from "react-bootstrap";
import React from "react";
import {DateInput} from "../DateInput";

const DatePlanForm = (props) => {
    return (
        <>
            <Row className={'searchForm_container rounded-3 fs-6 px-0 mx-0 mb-4'}>
                <Col className={'bg-orange text-white text-start rounded-top-3 align-items-center'}>
                    <Row>
                        <Col md={8}>
                            <p className={'mb-0 py-2'}>Ripianifica Data</p>
                        </Col>
                    </Row>
                </Col>

                <Form className={'row py-3 text-start'} id={'searchForm'}>
                    <Form.Group className={'col-md-8 my-2'}>
                        <Row>
                            <Form.Label className={'col-md-3'}>Ripianifica le dispensazioni selezionate per il</Form.Label>
                            <Col className={'col-md-8 '}>
                                <DateInput name={'shipping_at'} minDate={new Date()} required={true} />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Col className={'col-md-4 text-end'}>
                        <Button variant="primary" type="submit" onClick={props.handleMassiveUpdateShippingAt} className={'mt-3 rounded-5 px-5  text-uppercase'}>
                            Conferma
                        </Button>
                    </Col>
                </Form>
            </Row>
        </>
    )
}

export default DatePlanForm