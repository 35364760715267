import {Col, Form, InputGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {formatDataIt} from "../../../Utils/DateFormat";
import {Role} from "../../../Utils/Role";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";

const DurationInfo = (props) => {
    const [editing, setEditing] = useState(false);
    const [note, setNote] = useState('');
    const [role, setRole] = useState();

    //setRifOrder
    useEffect(() => {
        if (props.orderDetail) {
            setNote(props.orderDetail.notes ?? '');
        }
    }, [props.orderDetail]);

    //setRole
    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    //Handle Edit Note
    const handleEdit = () => {
        setEditing(true);
    };

    //Confirm change note
    const handleConfirm = () => {
        // Handle the confirmation logic here, e.g., update the value in the database
        setEditing(false);
        props.handleNotesChange(note)
    };

    //Confirm delete rif order
    const handleDelete = () => {
        // Handle the deletion logic here
        setNote(props.orderDetail ? props.orderDetail.notes : '');
        setEditing(false);
    };

    return (<Row className={'rounded-3 fs-6 px-0 searchForm_container mx-0 res_box box-2 d-flex align-content-start'}>
        <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
            <Row>
                <Col md={8}>
                    <p className={'mb-0 py-2'}>Durata</p>
                </Col>
            </Row>
        </Col>

        <Row className={'py-4 px-4'}>
            <Form.Group className={'my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Data Creazione</Form.Label>
                    <Col className={'col-md-9'}>
                        <Form.Control type={'text'} name={"created_at"} readOnly={true} value={props.orderDetail && props.orderDetail.created_at && formatDataIt(new Date(props.orderDetail.created_at)) }/>
                    </Col>
                </Row>

            </Form.Group>


            <Form.Group className={' my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Data approvazione</Form.Label>
                    <Col className={'col-md-9 '}>
                        <Form.Control type={'text'} readOnly={true} value={props.orderDetail && props.orderDetail.status_id === 1 && props.orderDetail.last_changed_at ? formatDataIt(new Date(props.orderDetail.last_changed_at)) : '' }/>
                    </Col>
                </Row>

            </Form.Group>

            <Form.Group className={'my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Data Scadenza</Form.Label>
                    <Col className={'col-md-9 '}>
                        <Form.Control type="text" readOnly={true} value={props.orderDetail && props.orderDetail.expiry ? formatDataIt(new Date(props.orderDetail.expiry)) : 'Illimitato'} />
                    </Col>
                </Row>
            </Form.Group>


            <Form.Group className={' my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Durata</Form.Label>
                    <Col className={'col-md-9 '}>
                        <Form.Control type="text" name={"duration_months"} readOnly={true} value={props.orderDetail && props.orderDetail.duration } />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group className={'my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Note</Form.Label>
                    <Col className={'col-md-9 '}>
                        <InputGroup className={'d-flex align-content-stretch'}>
                            <Form.Control maxLength={200} as={'textarea'} name={'note'} readOnly={!editing} disabled={!editing} value={note} onChange={(e) => setNote(e.target.value)} style={{ resize: 'vertical' }}></Form.Control>
                            {
                                role && !['data_entry', 'support'].includes(role) && (
                                    editing ? (
                                        <>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <InputGroup style={{ height: '100%' }}>
                                                    <InputGroup.Text onClick={handleConfirm} title={'Conferma'} style={{display: 'flex', alignContent: 'stretch', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                                                        <AiOutlineCheck color={'green'}/>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <InputGroup style={{ height: '100%' }}>
                                                    <InputGroup.Text onClick={handleDelete} title={'Annulla'} style={{display: 'flex', alignContent: 'stretch', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0}}>
                                                        <AiOutlineClose color={'red'}/>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </div>
                                        </>
                                    ) : (
                                        <InputGroup.Text onClick={handleEdit} title={'Modifica'}>
                                            <AiOutlineEdit/>
                                        </InputGroup.Text>
                                    )
                                )
                            }
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </Row>
    </Row>)
}

export default DurationInfo