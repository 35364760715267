import React, {useContext} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {Alert, Container} from "react-bootstrap";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Login from "../../Pages/Auth/Login";
import '../../assets/css/Icon.css'
import '../../assets/css/DateInput.css'
import '../../assets/css/Custom.css'


import AuthContext from "../../Contexts/authContext";
import ResetPassword from "../../Pages/Auth/ResetPassword";
import Footer from "./Footer";
import UpdatePassword from "../../Pages/Auth/UpdatePassword";

const Layout = () => {
    const {isAuthenticated, messagePasswordExpiry} = useContext(AuthContext)
    const location = useLocation();

    return (
        <>
            {location.pathname === '/update-password' ? (
                <UpdatePassword />
            ) : location.pathname === '/reset-password' ? (
                <ResetPassword />
            ) : isAuthenticated ? (
                <>
                    <Header/>
                    <Container className={'layoutContainer mw-100 mx-0 d-flex p-0'}>
                        <Sidebar/>
                        <div className="container-fluid mainContent px-0" style={{paddingTop: '50px', paddingBottom: '100px'}}>
                            {messagePasswordExpiry && <Alert variant={'warning'} className={'text-center'} dismissible={true}>
                                <a className={'text-decoration-none text-black hover-link'} href='/update-password'>{messagePasswordExpiry}</a>
                            </Alert>}
                            {/*<Breadcrumb/>*/}
                            <div className={'px-5 me-auto mw-100 mx-0 w-100'}>
                                <Outlet />
                            </div>
                        </div>
                    </Container>
                    <Footer />
                </>
            ) : (
                <Login/>
            )}
        </>
    )
}
export default Layout