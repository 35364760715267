import React, {useContext, useState, useCallback, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {loginAutocomplete} from "../Api/Geo";
import {logoutFetch} from "../Api/Auth/LogoutFetch";

const AuthContext = React.createContext();

export const AuthContextProvider = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    const [user, setUser] = useState([]);
    const [jwt, setJwt] = useState('');
    const [sanctum, setSanctum] = useState('');
    const [expiration, setExpiration] = useState();
    const [role, setRole] = useState(null)
    const [isMultipayer, setIsMultipayer] = useState(false)
    const [passwordExpiry, setPasswordExpiry] = useState(false)
    const [messagePasswordExpiry, setMessagePasswordExpiry] = useState()
    const [skipAddress, setSkipAddress] = useState(false)
    const [roleId, setRoleId] = useState(null)

    const login = (userData, sanctum , jwt, expiration) => {
        setUser(userData);
        setJwt(jwt);
        setSanctum(sanctum);
        setExpiration(expiration);
        setRole(JSON.parse(userData).role.code)
        setIsMultipayer(JSON.parse(userData).is_multipayer)
        setPasswordExpiry(JSON.parse(userData).must_change_password)
        setMessagePasswordExpiry(JSON.parse(userData).expiry_message)
        setSkipAddress(JSON.parse(userData).skip_address)
        setRoleId(JSON.parse(userData).role.id)

        if (!!sanctum && !!jwt && !!expiration) {
            localStorage.setItem('sanctum', sanctum);
            localStorage.setItem('jwt', jwt);
            localStorage.setItem('userData', userData)
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('expiration', expiration)
            setIsAuthenticated(localStorage.getItem('isAuthenticated'));
        }
    }

    const logout =() => {
        setIsAuthenticated(false);
        setUser(null);
        setJwt('');
        localStorage.clear();

        navigate('/')
    }

    //controlla lo stato del token, se è autenticato e se è un ruolo esistente per reindirizzare alla home
    useEffect(() => {
        let expiration = localStorage.getItem('expiration');
        let isAuthenticated = localStorage.getItem('isAuthenticated');
        let userData = localStorage.getItem('userData');
        let role = userData ? JSON.parse(userData).role.code : null;
        let rolePermission = ['data_entry', 'superuser', 'single_user', 'referente_aziendale', 'support', 'wematica'];
        let roleId = userData ? JSON.parse(userData).role.id : null;
        let skipAddress = userData ? JSON.parse(userData).skip_address : false;

        //check if user is multipayer
        let multipayer = userData ? JSON.parse(userData).is_multipayer : false;
        setIsMultipayer(multipayer)
        setRole(role)
        setRoleId(roleId)

        if(location.pathname !== '/reset-password'){
            if(isAuthenticated !== 'true' || (!!role && !rolePermission.includes(role)) || expiration === null || new Date() > new Date(expiration)){
                sessionStorage.setItem('redirect', location.pathname)
                logout()
            }else{
                if(location.pathname === '/') navigate('/lista-pazienti')
            }
        }else{
            if(isAuthenticated === 'true'){
                navigate('/home')
            }
        }
    }, []);

    return (
        <AuthContext.Provider value={{login, logout, isAuthenticated, user, sanctum, jwt, expiration, role, isMultipayer, passwordExpiry, messagePasswordExpiry, roleId, skipAddress}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext