import {Alert, Col, Row, Form, Button, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import SearchResendDispensation from "../../Components/Settings/SearchResendDispensation";
import {findPendingTicket, findResendDispensation, forceResendDispensation} from "../../Api/Settings/SettingsFetch";
import {TailSpin} from "react-loader-spinner";
import PatientsInfo from "../../Components/Orders/AddFormBox/PatientsInfo";
import TableResendDispensation from "../../Components/Settings/TableResendDispensation";
import TrackingModal from "../../Components/Modal/TrackingModal";
import {downloadDispensationPod} from "../../Api/Dispensations/DispensationsFetch";
import {emptyError} from "../../Components/Errors";
import {AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit} from "react-icons/ai";
import IconTooltip from "../../Components/Layout/IconsTooltip";

const ResendDispensation = () => {
    const [incrementId, setIncrementId] = useState('')
    const [loading, setIsLoading] = useState(false)
    const [resendDispensation, setResendDispensation] = useState()
    const [showModal, setShowModal] = useState(false);
    const [trackerDispensationId, setTrackerDispensationId] = useState(null);
    const [message, setMessage] = useState();
    const [errors, setErrors] = useState()
    const [ticketCode, setTicketCode] = useState('')
    const [tickets, setTickets] = useState([])
    const [pendingTickets, setPendingTickets] = useState([])
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        setErrors(undefined)
        setResendDispensation(undefined)

        if(incrementId !== ''){
            findResendDispensation(incrementId, {setResendDispensation, setIsLoading, setErrors, setTickets})
        }else{
            findPendingTicket({setPendingTickets, setIsLoading, setErrors, setTickets})
        }
    }, [incrementId]);

    //download pod for specific dispensation
    const handleDownloadPod = (e) => {
        const id = e.currentTarget.getAttribute('data-id')
        const type = e.currentTarget.getAttribute('data-type')

        if(id !== null){
            downloadDispensationPod(id, type, {setMessage, setErrors, setIsLoading})
        }
    }

    //force resend dispensation
    const handleResendDispensation = (e) => {
        e.preventDefault()
        emptyError()

        const emptyfields = document.querySelector('span.error_empty_field')
        if (!emptyfields) {
            forceResendDispensation(incrementId, ticketCode, {setIsLoading, setMessage, setErrors})
        }
    }

    return <>
        <Row className={'text-start mt-4'}>
            <h2>Reinvia Dispensazione</h2>
        </Row>

        <SearchResendDispensation setIncrementId={setIncrementId} incrementId={incrementId}/>

        {!incrementId && (<Row className={'mt-3'}>
            <Col md={12} className={'text-start'}>
                <span className={'fw-bold'}>Ticket in attesa: </span><br />
                <Table striped bordered className={'rounded-bottom-2'}>
                    <thead>
                    <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '10%'}}>Code</th>
                        <th style={{width: '60%'}}>Description</th>
                        <th style={{width: '5%'}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {pendingTickets && pendingTickets.map((ticket, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{ticket.code}</td>
                            <td>{ticket.description}</td>
                            <td>
                                <IconTooltip title="Riprocessa" className={'d-inline mx-1 px-0'}>
                                    <AiOutlineEdit className={'text-secondary fs-3'} data-id={ticket.increments[0]} onClick={() => {
                                        setIncrementId(ticket.increments[0])
                                        setTicketCode(ticket.code)
                                    }} />
                                </IconTooltip>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Col>
        </Row>)}

        <br></br>

        {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
        {errors && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

        {resendDispensation && (
            <>
                <PatientsInfo orderDetail={resendDispensation?.order}></PatientsInfo>
                <br></br>
                <TableResendDispensation dispensation={resendDispensation} setTrackerDispensationId={setTrackerDispensationId} setShowModal={setShowModal} handleDownloadPod={handleDownloadPod}></TableResendDispensation>
                <TrackingModal show={showModal} handleClose={handleClose} dispensationId={trackerDispensationId} />
                <Row className={'align-items-end'}>
                    <Col md={3} className={'text-start'}>
                        <Form.Label>Ticket Code</Form.Label>
                        <Col className={'position-relative'}>
                            <Form.Control className={'d-inline form-control-sm'} required={true} id={'ticket_code'} name={'ticket_code'} placeholder='Ticket Code' value={ticketCode} onChange={(e) => setTicketCode(e.target.value)}></Form.Control>
                        </Col>
                    </Col>
                    <Col md={9} className={'text-end'}>
                        <Button className={'btn btn-primary'} onClick={handleResendDispensation}>Riprocessa</Button>
                    </Col>
                    <Col md={12} className={'text-start mt-3'}>
                        <span className={'fw-bold'}>Ticket Associati:</span> <br />
                        {tickets?.length > 0 && tickets.map((ticket, index) => {
                            return <><span className={'small'}>{index + 1}) {ticket}</span><br /></>
                        }) || (<span className={'small'}>Nessun Ticket Associato</span> )}
                    </Col>
                </Row>
            </>
        )}

        {loading &&
            <TailSpin
                height="80"
                width="80"
                color="var(--primary)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                visible={true}/>
        }
    </>
}

export default ResendDispensation