import axiosInstance from "../AxiosInstance";

//find dispensation of resend
export const findResendDispensation = (incrementId, {setResendDispensation, setIsLoading, setErrors, setTickets}) => {
    setIsLoading(true);

    axiosInstance.get('/api/settings/v1/dispensation/' + incrementId)
        .then((response) => {
        // Handle successful response (status code 200)
        setResendDispensation(response.data.data)
        setTickets(response.data.data.tickets)
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setResendDispensation(error.response.data.data)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors('Errore ricerca dispensazione!')
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//force resend dispensation with increment id and check ticket code
export const forceResendDispensation = (incrementId, ticketCode, {setIsLoading, setMessage, setErrors}) => {
    setIsLoading(true);

    axiosInstance.post('/api/dispensations/v1/newShipment/' + incrementId + '/force', {
        ticket_code: ticketCode
    }).then((response) => {
            // Handle successful response (status code 200)
            setMessage('Dispensazione riprocessata con successo!')
            setIsLoading(false)
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message ?? 'Errore riprocessamento dispensazione!')
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//find pending ticket
export const findPendingTicket = ({setPendingTickets, setIsLoading, setErrors}) => {
    setIsLoading(true);

    axiosInstance.get('/api/settings/v1/dispensation/pending')
        .then((response) => {
            // Handle successful response (status code 200)
            setPendingTickets(response.data.data)
            setIsLoading(false)
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors('Errore ricerca ticket in attesa!')
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//list of user
export const retrieveUsers = ({setUsers, setErrors}) => {
    axiosInstance.get('/api/settings/v1/admin_users')
        .then((response) => {
            // Handle successful response (status code 200)
            setUsers(response.data.data)
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors('Errore ricerca dispensazione')
            console.error('Error:', error);
        }
    })
}