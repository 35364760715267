import axiosInstance from "../AxiosInstance";

export const logoutFetch = () => {
    axiosInstance.post('/api/auth/v1/logout').then((response) => {
        // Handle successful response (status code 200)
        console.log(response)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)

            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)

            console.error('Error:', error);
        }
    });
}

