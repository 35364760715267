import React, {useEffect, useState} from "react";
import CustomTable from "../../Components/Table/CustomTable";
import {Alert, Row} from "react-bootstrap";
import SearchPatientsForm from "../../Components/Patients/SearchForm";
import {exportPatientList, retrievePatientsList} from "../../Api/Patients/PatientsFetch";
import {TailSpin} from "react-loader-spinner";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";

const PatientsList = () => {
    const [patientsData, setPatientsData] = useState({})
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/api/patients/v1')
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [show, setShow] = useState()
    const [errors, setErrors] = useState();
    const [loading, setIsLoading] = useState(true)
    const [filterSort, setFilterSort] = useState('')
    const [records, setTotalRecords] = useState()
    const [message, setMessage] = useState();

    useEffect(() => {
        generateRandomStringToAddInputName()
    }, [])

    useEffect(() => {
        retrievePatientsList({
            setPatientsData,
            setIsLoading,
            setPagination,
            perPage,
            pageUrl,
            search,
            filterSort,
            setTotalRecords,
            setErrors
        })
    }, [perPage, filterSort, pageUrl, search])

    //export table
    const handleExportTable = (e) => {
        clearInputNameRandomString()
        const form = document.getElementById('searchForm');
        const formData = new FormData(form);

        exportPatientList(formData, {setIsLoading, setErrors, setMessage})

        generateRandomStringToAddInputName()
    }


    return (
        <>

            <Row className={'container-fluid w-100 mx-0 pt-5'}>
                <SearchPatientsForm setSearch={setSearch} search={search} setPageUrl={setPageUrl}></SearchPatientsForm>

                {message && <Alert className={'mt-4'} variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
                {errors && <Alert className={'mt-4'} variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

                <CustomTable patientList={patientsData} pagination={pagination} setPerPage={setPerPage}
                             perPage={perPage} setPageUrl={setPageUrl} setFilterSort={setFilterSort}
                             filterSort={filterSort} records={records} handleExportTable={handleExportTable}>

                </CustomTable>
            </Row>
            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default PatientsList