import {Table, Form, Row, Alert} from "react-bootstrap";
import React, {useState} from "react";
import {enableDisableProduct} from "../../Api/Products/ProductsFetch";

const ProductsTable = (props) => {
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null)

    //update product status
    const handleChangeStatus = (e) => {
        const sku = e.target.getAttribute('data-sku');
        const is_active = e.target.checked ? 1 : 0;

        enableDisableProduct(sku, is_active, {setMessage, setError});
    }

    return (
        <>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {error && <Alert variant={'danger'} onClick={() => {setError(null) }} dismissible> {error} </Alert>}

            <Table striped bordered className={'rounded-bottom-2 mt-3'}>
                <thead className={'rounded-2'}>
                <tr className={'rounded-top-2'}>
                    <th className={'bkg-primary text-white'}>SKU</th>
                    <th className={'bkg-primary text-white'}
                        data-name={'dispensation_id'}>Descrizione
                    </th>
                    <th className={'bkg-primary text-white'}
                        data-name={'dispensation_date'}>Stato
                    </th>
                </tr>
                </thead>
                <tbody>
                    {props.products && props.products.map((product, index) => {
                        return (
                            <tr key={index}>
                            <td>{product.sku}</td>
                            <td>{product.description}</td>
                                <td>
                                    <Row className={'justify-content-center'}>
                                        <Form className={'col-md-3'}>
                                            <Form.Switch label={'Attivo'} data-sku={product.sku} defaultChecked={!!(product.is_active && product.is_active === true)} onChange={handleChangeStatus}></Form.Switch>
                                        </Form>
                                    </Row>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}

export default ProductsTable