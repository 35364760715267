//retrieve dispensation list
import axiosInstance from "../AxiosInstance";
import {getFilenameFromContentDisposition} from "../../Utils/GetHeaderFileName";

//retrive fee billing list
export const retrieveBillingList = ({setBillingList, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination, setErrors}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setBillingList(response.data.data.data)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrive fee billing list
export const retrieveBillingFeeList = ({setBillingFeeList, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination, setErrors, setTotalDays}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setBillingFeeList(response.data.data.data)
        setPagination(response.data.data.links)
        setTotalDays(response.data.total)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//retrieve quantity billing list
export const retrieveBillingQuantityList = ({setBillingQuantityList, pageUrl, search,perPage, filterSort, setIsLoading, setTotalRecords, setPagination, setErrors, setTotalSensor, setInvoiceSplitNumber}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';

    let url = pageUrl + symbol + perPage;

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(query  !== null) url += '&' + query;
    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        setBillingQuantityList(response.data.data.data)
        setPagination(response.data.data.links)
        setTotalSensor(response.data.total)
        setInvoiceSplitNumber(response.data.invoice_split_number)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//save search of fee list
export const saveFeeList = (data, {setErrors, setMessage, setIsLoading, setSearch, navigate}) => {
    setIsLoading(true)
    axiosInstance.post('/api/billing/v1/fee/save', data).then((response) => {
        setSearch({})
        setMessage(response.data.message)

        setTimeout(() => {
            setIsLoading(false)
            response.data.invoice_id !== null ? navigate('/dettaglio-fattura/' + response.data.invoice_id) : navigate('/lista-fatture/')
        }, 1000)
    }).catch((error) => {
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                setErrors(error.response.data.message)
                setIsLoading(false)
                console.log('Validation Errors:', error.response.data.message);
            } else {
                // Handle other errors (e.g., network issues)
                setErrors('Errore salvataggio!')
                setIsLoading(false)
                console.error('Error:', error);
            }
            document.querySelector('.mainContent').scrollIntoView({ behavior: 'smooth' })
        })
}

//save search of quantity list
export const saveQuantityList = (data, {setErrors, setMessage, setIsLoading, setSearch, navigate}) => {
    setIsLoading(true)
    axiosInstance.post('/api/billing/v1/quantity/save', data).then((response) => {
        setMessage(response.data.message)
        setSearch({})

        setTimeout(() => {
            setIsLoading(false)
            response.data.invoice_id !== null ? navigate('/dettaglio-fattura/' + response.data.invoice_id) : navigate('/lista-fatture/')
        }, 1000)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors('Errore salvataggio!')
            setIsLoading(false)
            console.error('Error:', error);
        }
        document.querySelector('.mainContent').scrollIntoView({ behavior: 'smooth' })
    })
}

//get data detail billing
export const getDetailBilling = (id, {setBillingDetail, setErrors, setMessage, setIsLoading}) => {
    setIsLoading(true)
    axiosInstance.get('/api/billing/v1/invoice/' + id).then((response) => {
        setBillingDetail(response.data.data)
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
        document.querySelector('.mainContent').scrollIntoView({ behavior: 'smooth' })
    })
}

//retrive detail billing list
export const getBillingDetailList = (billingType, {setBillingFeeList, setBillingQuantityList, pageUrl ,perPage, setIsLoading, setTotalRecords, setPagination, setErrors, setTotalDays, setTotalSensor, filterSort}) => {
    setIsLoading(true);
    const symbol = pageUrl.includes('?') ? '&' : '?';
    let url = pageUrl + symbol + perPage;

    const filter = filterSort ? Object.keys(filterSort)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
        .join('&') : null;

    if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        setTotalRecords(response.data.data.total)
        billingType === 1 ? setBillingQuantityList(response.data.data.data) : setBillingFeeList(response.data.data.data)
        billingType === 1 ? setTotalSensor(response.data.total_sensors) : setTotalDays(response.data.total_days)
        setPagination(response.data.data.links)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const updateBilling = (id, data, setErrors, setMessage, setIsLoading) => {
    setIsLoading(true)

    axiosInstance.post('/api/billing/v1/invoice/' + id + '/update', data).then((response) => {
        setMessage(response.data.message)
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
        document.querySelector('.mainContent').scrollIntoView({ behavior: 'smooth' })
    })
}

//export billing fee in xlsx or pdf
export const exportBillingFee = (search, type, {setIsLoading, setErrors}) => {
    setIsLoading(true)
    let url = '/api/billing/v1/fee/' + type

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    if(query  !== null) url += '?' + query;

    //export = xls, not export = pdf
    if(type === 'export'){
        fetch(process.env.REACT_APP_BASE_URL + url,
            {
                method: "GET",
                responseType: 'blob', // Important,
                headers: {
                    "X-JWT-Token": localStorage.getItem('jwt'),
                    "Authorization": "Bearer " + localStorage.getItem('sanctum'),
                }
            }
        ).then((res) => {
            const filename = getFilenameFromContentDisposition(res.headers);
            return res.blob().then((blob) => ({ filename, blob }));
        }).then(({ filename, blob }) => {
            const outputFilename = filename ?? `export_canone.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            setTimeout(() => {
                link.click();
                document.body.removeChild(link); // Remove the link after download
            }, 100);
            setIsLoading(false)
        }, err => {
            console.log(err)
            setErrors("Errore esportazione. Contatta l'amministratore!")
        });
    }else{
        axiosInstance.get(url).then((response) => {
            window.location.href = response.data.data.url;
            setIsLoading(false)
        }).catch((error) => {
            //  setIsLoading(false)
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                setErrors(error.response.data.errors)
                setIsLoading(false)
                console.log('Validation Errors:', error.response.data.message);
            } else {
                console.log(error)
                // Handle other errors (e.g., network issues)
                setErrors(error.response.data.message)
                setIsLoading(false)
                console.error('Error:', error);
            }
        });
    }
}

//export billing quantity in xlsx or pdf
export const exportBillingQuantity = (search, type, {setIsLoading, setErrors}) => {
    setIsLoading(true)
    let url = '/api/billing/v1/quantity/' + type

    const query= search ? Object.keys(search)
        .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : null;

    if(query  !== null) url += '?' + query;

    //export = xls, not export = pdf
    if(type === 'export') {
        fetch(process.env.REACT_APP_BASE_URL + url,
            {
                method: "GET",
                responseType: 'blob', // Important,
                headers: {
                    "X-JWT-Token": localStorage.getItem('jwt'),
                    "Authorization": "Bearer " + localStorage.getItem('sanctum'),
                }
            }
        ).then((res) => {
            const filename = getFilenameFromContentDisposition(res.headers);
            return res.blob().then((blob) => ({filename, blob}));
        }).then(({filename, blob}) => {
            const outputFilename = filename ?? `export_quantità.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            setTimeout(() => {
                link.click();
                document.body.removeChild(link); // Remove the link after download
            }, 100);
            setIsLoading(false)
        }, err => {
            console.log(err)
            setErrors("Errore esportazione. Contatta l'amministratore!")
        });
    }else{
        axiosInstance.get(url).then((response) => {
            window.location.href = response.data.data.url;
            setIsLoading(false)
        }).catch((error) => {
            //  setIsLoading(false)
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                setErrors(error.response.data.errors)
                setIsLoading(false)
                console.log('Validation Errors:', error.response.data.message);
            } else {
                console.log(error)
                // Handle other errors (e.g., network issues)
                setErrors(error.response.data.message)
                setIsLoading(false)
                console.error('Error:', error);
            }
        });
    }
}

//export billing detail list
export const exportDetailBillingList = (id, type, {setIsLoading, setErrors}) => {
    setIsLoading(true)

    switch (type){
        case 'pdf': {
            axiosInstance.get('/api/billing/v1/invoice/' + id +'/pdf').then((response) => {
                window.location.href = response.data.data.url;
                setIsLoading(false)
            }).catch((error) => {
                //  setIsLoading(false)
                if (error.response && error.response.status === 422) {
                    // Handle the 422 response (validation error)
                    setErrors(error.response.data.errors)
                    setIsLoading(false)
                    console.log('Validation Errors:', error.response.data.message);
                } else {
                    console.log(error)
                    // Handle other errors (e.g., network issues)
                    setErrors(error.response.data.message)
                    setIsLoading(false)
                    console.error('Error:', error);
                }
            });
        } break;
        case 'txt': {
            fetch(process.env.REACT_APP_BASE_URL + '/api/billing/v1/invoice/' + id + '/diabetica',
                {
                    method: "GET",
                    responseType: 'blob', // Important,
                    headers: {
                        "X-JWT-Token": localStorage.getItem('jwt'),
                        "Authorization": "Bearer " + localStorage.getItem('sanctum'),
                    }
                }
            ).then((res) => {
                const filename = getFilenameFromContentDisposition(res.headers);
                return res.blob().then((blob) => ({ filename, blob }));
            }).then(({ filename, blob }) => {
                const outputFilename = filename ?? `export_diabetica.txt`;

                // If you want to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([blob]), {type: 'text/plain'});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                setTimeout(() => {
                    link.click();
                    document.body.removeChild(link); // Remove the link after download
                }, 100);
                setIsLoading(false)
            }, err => {
                console.log(err)
                setErrors("Errore esportazione. Contatta l'amministratore!")
            });
        } break;
        default: {
            fetch(process.env.REACT_APP_BASE_URL + '/api/billing/v1/invoice/' + id +'/export',
                {
                    method: "GET",
                    responseType: 'blob', // Important,
                    headers: {
                        "X-JWT-Token": localStorage.getItem('jwt'),
                        "Authorization": "Bearer " + localStorage.getItem('sanctum'),
                    }
                }
            ).then((res) => {
                const filename = getFilenameFromContentDisposition(res.headers);
                return res.blob().then((blob) => ({ filename, blob }));
            }).then(({ filename, blob }) => {
                const outputFilename = filename ?? `export_dettaglio_fattura.xslx`;

                // If you want to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([blob]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                setTimeout(() => {
                    link.click();
                    document.body.removeChild(link); // Remove the link after download
                }, 100);
                setIsLoading(false)
            }, err => {
                console.log(err)
                setErrors("Errore esportazione. Contatta l'amministratore!")
            });
        }
    }
}

export const enableDisableItemBilling = (id, action, item_id, {setErrors, setMessage, setIsLoading}) => {
    setIsLoading(true)

    axiosInstance.post('/api/billing/v1/invoice/' + id + '/' + action, {
        'item_id': item_id
    }).then((response) => {
        setMessage(response.data.message)
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

//get status by payer id in a search billing
export const getStatusesSearch = (payerId, billingType, {setErrors, setStatusPayers}) => {
    axiosInstance.get('/api/billing/v1/status?payer_id=' + payerId + '&billing_type=' + billingType)
        .then((response) => {
            setStatusPayers(response.data.data);
        })
        .catch((error) => {
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                setErrors(error.response.data.message)
                console.log('Validation Errors:', error.response.data.message);
            } else {
                // Handle other errors (e.g., network issues)
                setErrors(error)
                console.error('Error:', error);
            }
        })
};