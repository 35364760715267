import React, {useEffect, useState} from 'react';
import {Modal, Button, Form, Row, Alert, Col} from 'react-bootstrap';
import {emptyError, handleErrors} from "../Errors";
import {addTicket, getCategories, getPriorities} from "../../Api/Tickets/TicketFetch";
import {TailSpin} from "react-loader-spinner";

const AddTicketModal = ({show, handleClose}) => {
    const [errors, setErrors] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState()
    const [priorities, setPriorities] = useState()
    const [idTicket, setIdTicket] = useState();

    useEffect(() => {
        if(show === true){
            getCategories({setCategories, setErrors, setIsLoading})
            getPriorities({setPriorities, setErrors, setIsLoading})
        }
    }, [show])

    //handle errors
    useEffect(() => {
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    const handleSubmit = () => {
        const form = document.getElementById('formAddTicket');
        const formData = new FormData(form);

        emptyError('.modal ');

        const emptyfields = document.querySelector('span.error_empty_field')
        if (!emptyfields) {
            addTicket(formData, {setErrors, setIsLoading, setMessage, setIdTicket})
        }
    }

    //on change subcategory set category id
    const handleCategoryChange = (e) => {
        document.getElementById('category_id').value = e.target.options[e.target.selectedIndex].getAttribute('data-category-id');
    }

    return (
        <Modal show={show} onHide={handleClose} className={'modal-lg'} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Crea un nuovo ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <Alert variant={'success'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message}</Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}

                <Form id={'formAddTicket'} className={`formAdd`} autoComplete="off">
                    <Form.Group className={'my-2'}>
                        <Row className={'d-flex  align-items-center '}>
                            <Form.Label className={'col-md-2 mb-0'}>Titolo</Form.Label>
                            <Col className={'col-md-4'}>
                                <Form.Control type={'text'} name={'title'} required={'required'}></Form.Control>
                            </Col>
                            <Form.Label className={'col-md-2 mb-0'}>Priorità</Form.Label>
                            <Col className={'col-md-4'}>
                                <Form.Select name={'priority_id'} required={'required'}>
                                    <option value={''}>-- Seleziona --</option>
                                    {priorities && priorities.map((priority, index) => {
                                        return <option key={index} value={priority.id}>{priority.name}</option>
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row className={'d-flex align-items-center'}>
                            <Form.Label className={'col-md-2 mb-0'}>Categoria</Form.Label>
                            <Col className={'col-md-10'}>
                                <Form.Select name={'subcategory_id'} required={'required'}
                                             onChange={handleCategoryChange}>
                                    <option value={''}>-- Seleziona --</option>
                                    {categories && categories.map((category, index) => {
                                        return (
                                            <optgroup key={index} label={category.name}>
                                                {category.subcategories && category.subcategories.map((subcategory, index) => {
                                                    return <option key={index}
                                                                   data-category-id={subcategory.category_id}
                                                                   value={subcategory.id}>{subcategory.name}</option>
                                                })};
                                            </optgroup>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Control id={'category_id'} type={'hidden'} name={'category_id'} value={0}></Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className={'my-2'}>
                        <Row className={'d-flex  align-items-center '}>
                            <Form.Label className={'col-md-2 mb-0'}>Descrizione</Form.Label>
                            <Col className={'col-md-10'}>
                                <Form.Control as="textarea" rows={4} name={'description'}
                                              required={'required'}></Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className={'my-2'}>
                        <Row className={'d-flex  align-items-center '}>
                            <Form.Label className={'col-md-2 mb-0'}>Carica File</Form.Label>
                            <Col className={'col-md-10'}>
                                <Form.Control type={"file"} rows={4} name={'file'}></Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {message && <><p className={'small'}>Se vuoi vedere o aggiungere informazioni più dettagliate vai nel <a target={'_blank'}
                    href={process.env.REACT_APP_TICKET_URL + '/auth?service=libredesk&jwt=' + localStorage.getItem('jwtTicket') + '&id=' + idTicket} style={{color: 'blue'}}>dettaglio.</a></p>
                    <Button variant="secondary" onClick={handleClose}>Chiudi</Button></> || <Button variant="secondary" onClick={handleClose}>Annulla</Button>}
                {!message && <Button variant="primary" onClick={handleSubmit}>Invia</Button>}
            </Modal.Footer>
            {
                loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </Modal>
    );
};

export default AddTicketModal;
